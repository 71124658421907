ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: #353535;
}

img {
  width: 100%;
  height: auto;
}

h1, h2, h3, h4, p, span {
  color: #353535;
}

.sp-br {
  display: none;
}
@media screen and (max-width: 420px) {
  .sp-br {
    display: block;
  }
}

.swiper-button-prev:after {
  content: "" !important;
  background: url("../../dest/img/arrow-prev.png") center/contain no-repeat;
  width: 50px;
  height: 50px;
}

.swiper-button-next:after {
  content: "" !important;
  background: url("../../dest/img/arrow-next.png") center/contain no-repeat;
  width: 50px;
  height: 50px;
}

.swiper-pagination-bullet-active {
  background: #00a8ff !important;
}

header {
  width: 100%;
  height: auto;
  position: fixed;
  z-index: 9998;
  transition: all 0.5s;
}
header .header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
}
@media screen and (max-width: 420px) {
  header .header-container {
    padding: 1rem;
  }
}
header .header-container .header-left {
  display: inline-block;
  z-index: 9998;
  width: 20%;
  height: auto;
}
@media screen and (max-width: 1200px) {
  header .header-container .header-left {
    width: 30%;
  }
}
@media screen and (max-width: 420px) {
  header .header-container .header-left {
    width: 55%;
    transform: translateX(-12px);
  }
}
header .header-container .header-right .nv nav {
  display: block;
  position: fixed;
  top: 0;
  right: -300px;
  bottom: 0;
  width: 400px;
  background: linear-gradient(180deg, #f6bf75 5%, #d77185, #8766ac, #4150b1);
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  transition: all 0.5s;
  z-index: 3;
  opacity: 0;
}
header .header-container .header-right .nv nav.open {
  right: 0;
  opacity: 1;
}
@media screen and (max-width: 420px) {
  header .header-container .header-right .nv nav {
    right: -220px;
    width: 185px;
  }
}
header .header-container .header-right .nv nav .inner {
  padding: 25px;
}
@media screen and (max-width: 420px) {
  header .header-container .header-right .nv nav .inner {
    padding: 15px;
  }
}
header .header-container .header-right .nv nav .inner .inner-lists {
  margin: 7rem 0 0;
  padding: 0;
}
@media screen and (max-width: 1200px) {
  header .header-container .header-right .nv nav .inner .inner-lists {
    margin: 10rem 0 0;
  }
}
@media screen and (max-width: 420px) {
  header .header-container .header-right .nv nav .inner .inner-lists {
    margin: 8rem 0 0;
  }
}
header .header-container .header-right .nv nav .inner .inner-lists .inner-list {
  position: relative;
  margin: 0;
  border-bottom: 1px solid #fff;
}
header .header-container .header-right .nv nav .inner .inner-lists .inner-list .inner-list-btn {
  font-family: "Hiragino Sans", "Hiragino Kaku Gothic ProN", "MS Gothic", "Meiryo", sans-serif;
  font-weight: 400;
  font-style: normal;
  display: block;
  color: #fff;
  font-size: 1rem;
  padding: 2rem;
  transition-duration: 0.2s;
  letter-spacing: 2px;
}
header .header-container .header-right .nv nav .inner .inner-lists .inner-list .inner-list-btn:hover {
  background: #e4e4e4;
}
@media screen and (max-width: 1200px) {
  header .header-container .header-right .nv nav .inner .inner-lists .inner-list .inner-list-btn {
    font-size: 1.6rem;
    letter-spacing: 4px;
  }
}
@media screen and (max-width: 420px) {
  header .header-container .header-right .nv nav .inner .inner-lists .inner-list .inner-list-btn {
    font-size: 0.8rem;
    letter-spacing: 1.5px;
    padding: 1.6rem 0.5rem;
  }
}
header .header-container .header-right .nv .toggle_btn {
  display: block;
  position: relative;
  top: 3px;
  right: 0;
  width: 60px;
  height: 30px;
  transition: all 0.5s;
  cursor: pointer;
  z-index: 9999;
}
header .header-container .header-right .nv .toggle_btn span {
  display: block;
  position: absolute;
  left: 0;
  width: 60px;
  height: 4px;
  background-color: #353535;
  border-radius: 4px;
  transition: all 0.5s;
}
header .header-container .header-right .nv .toggle_btn span:nth-child(1) {
  top: 4px;
}
header .header-container .header-right .nv .toggle_btn span:nth-child(2) {
  top: 14px;
}
header .header-container .header-right .nv .toggle_btn span.open:nth-child(1) {
  -webkit-transform: translateX(15px) translateY(10px) rotate(-315deg);
  transform: translateX(15px) translateY(10px) rotate(-315deg);
  width: 30px;
  background: #fff;
}
header .header-container .header-right .nv .toggle_btn span.open:nth-child(2) {
  -webkit-transform: translateX(15px) translateY(-10px) rotate(315deg);
  transform: translateX(15px) translateY(0) rotate(315deg);
  width: 30px;
  background: #fff;
}
header .header-container .header-right .nv #mask {
  display: none;
  transition: all 0.5s;
}
header .header-container .header-right .nv #mask.open {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.8;
  z-index: 2;
  cursor: pointer;
}

.scrolled {
  box-shadow: 2px 2px 4px 3px rgba(0, 0, 0, 0.2);
  background: linear-gradient(220deg, #f6bf75 5%, #d77185, #8766ac, #4150b1);
}

.hero {
  width: 100%;
  height: 100vh;
  background: url("../../dest/img/footer-background.png") center/cover no-repeat;
  overflow-x: clip;
}
@media screen and (max-width: 1200px) {
  .hero {
    height: 50vh;
  }
}
@media screen and (max-width: 768px) {
  .hero {
    height: 60vh;
  }
}
@media screen and (max-width: 420px) {
  .hero {
    height: 100svh;
    background: url("../../dest/img/sp-hero-back.png") center/cover no-repeat;
  }
}
.hero .hero-container {
  padding: 7rem 5rem;
}
@media screen and (max-width: 1530px) {
  .hero .hero-container {
    padding: 8rem 5rem;
  }
}
@media screen and (max-width: 1200px) {
  .hero .hero-container {
    padding: 8rem 3rem;
  }
}
@media screen and (max-width: 768px) {
  .hero .hero-container {
    padding: 10rem 3rem;
  }
}
@media screen and (max-width: 420px) {
  .hero .hero-container {
    padding: 10rem 1rem;
  }
}
.hero .hero-container .hero-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 420px) {
  .hero .hero-container .hero-inner {
    flex-direction: column-reverse;
    align-items: start;
  }
}
@media screen and (max-width: 420px) {
  .hero .hero-container .hero-inner .hero-inner-left {
    position: relative;
    z-index: 2;
    transform: translateY(-145px);
  }
}
.hero .hero-container .hero-inner .hero-inner-left .hero-head {
  font-family: "new-hero", sans-serif;
  font-weight: 900;
  font-style: italic;
  font-size: 7rem;
  letter-spacing: 4px;
  line-height: 1.1;
  margin-bottom: 5rem;
}
@media screen and (max-width: 1530px) {
  .hero .hero-container .hero-inner .hero-inner-left .hero-head {
    font-size: 6rem;
    margin-bottom: 3rem;
  }
}
@media screen and (max-width: 1200px) {
  .hero .hero-container .hero-inner .hero-inner-left .hero-head {
    font-size: 4rem;
    margin-bottom: 2rem;
  }
}
@media screen and (max-width: 768px) {
  .hero .hero-container .hero-inner .hero-inner-left .hero-head {
    font-size: 3rem;
  }
}
@media screen and (max-width: 420px) {
  .hero .hero-container .hero-inner .hero-inner-left .hero-head {
    font-size: 2.8rem;
    margin-bottom: 1rem;
    letter-spacing: 1px;
  }
}
.hero .hero-container .hero-inner .hero-inner-left .hero-content {
  font-family: "Hiragino Sans", "Hiragino Kaku Gothic ProN", "MS Gothic", "Meiryo", sans-serif;
  font-weight: bold;
  font-style: normal;
  font-size: 1.4rem;
  letter-spacing: 2px;
  line-height: 2.2;
}
@media screen and (max-width: 1530px) {
  .hero .hero-container .hero-inner .hero-inner-left .hero-content {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 1200px) {
  .hero .hero-container .hero-inner .hero-inner-left .hero-content {
    font-size: 1rem;
    white-space: nowrap;
  }
}
@media screen and (max-width: 768px) {
  .hero .hero-container .hero-inner .hero-inner-left .hero-content {
    font-size: 0.8rem;
    letter-spacing: 1px;
  }
}
@media screen and (max-width: 420px) {
  .hero .hero-container .hero-inner .hero-inner-left .hero-content {
    display: none;
  }
}
.hero .hero-container .hero-inner .hero-inner-left .sp-hero-content {
  font-family: "Hiragino Sans", "Hiragino Kaku Gothic ProN", "MS Gothic", "Meiryo", sans-serif;
  font-weight: bold;
  font-style: normal;
  font-size: 0.9rem;
  letter-spacing: 0.6px;
  line-height: 1.8;
  display: none;
}
@media screen and (max-width: 420px) {
  .hero .hero-container .hero-inner .hero-inner-left .sp-hero-content {
    display: block;
  }
}
.hero .hero-container .hero-inner .hero-inner-left .sp-hero-content-first {
  margin-bottom: 0.5rem;
}
.hero .hero-container .hero-inner .hero-inner-right {
  width: 50%;
}
@media screen and (max-width: 768px) {
  .hero .hero-container .hero-inner .hero-inner-right {
    width: 40%;
  }
}
@media screen and (max-width: 420px) {
  .hero .hero-container .hero-inner .hero-inner-right {
    width: 80%;
  }
}
@media screen and (max-width: 768px) {
  .hero .hero-container .hero-inner .hero-inner-right .hero-pic {
    transform: scale(1.2);
  }
}
@media screen and (max-width: 420px) {
  .hero .hero-container .hero-inner .hero-inner-right .hero-pic {
    transform: scale(1.6) translate(40px, -20px);
  }
}

.about-wrapper {
  width: 100%;
  height: auto;
  background: url("../../dest/img/material-1.png") center/cover no-repeat;
  position: relative;
  z-index: 3;
}
.about-wrapper .about-container {
  text-align: center;
  max-width: 70%;
  margin: 0 auto;
  padding: 8rem 0;
}
@media screen and (max-width: 1200px) {
  .about-wrapper .about-container {
    max-width: 90%;
    padding: 6rem 0;
  }
}
@media screen and (max-width: 420px) {
  .about-wrapper .about-container {
    padding: 6rem 0 3rem;
  }
}
.about-wrapper .about-container .about-head {
  font-family: "Hiragino Sans", "Hiragino Kaku Gothic ProN", "MS Gothic", "Meiryo", sans-serif;
  font-weight: bold;
  font-style: normal;
  font-size: 1.6rem;
  letter-spacing: 2px;
  line-height: 2;
  margin-bottom: 6rem;
  position: relative;
}
@media screen and (max-width: 1200px) {
  .about-wrapper .about-container .about-head {
    font-size: 1.5rem;
    letter-spacing: 1px;
  }
}
@media screen and (max-width: 768px) {
  .about-wrapper .about-container .about-head {
    font-size: 1.2rem;
    margin-bottom: 4rem;
  }
}
@media screen and (max-width: 420px) {
  .about-wrapper .about-container .about-head {
    z-index: 2;
    margin-bottom: 3rem;
  }
}
.about-wrapper .about-container .about-head:after {
  content: url("../../dest/img/material-2.png");
  position: absolute;
  top: -615px;
  right: -385px;
  transform: scale(0.16);
}
@media screen and (max-width: 1530px) {
  .about-wrapper .about-container .about-head:after {
    top: -615px;
    right: -450px;
    transform: scale(0.12);
  }
}
@media screen and (max-width: 768px) {
  .about-wrapper .about-container .about-head:after {
    top: -625px;
    right: -465px;
    transform: scale(0.1);
  }
}
@media screen and (max-width: 420px) {
  .about-wrapper .about-container .about-head:after {
    z-index: -1;
  }
}
.about-wrapper .about-container .about-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}
@media screen and (max-width: 1200px) {
  .about-wrapper .about-container .about-inner {
    gap: 1.4rem;
  }
}
@media screen and (max-width: 768px) {
  .about-wrapper .about-container .about-inner {
    gap: 1rem;
  }
}
@media screen and (max-width: 420px) {
  .about-wrapper .about-container .about-inner {
    display: none;
  }
}
.about-wrapper .about-container .about-inner .about-inner-item {
  background: #353535;
  border-radius: 20px;
  flex-basis: 50%;
}
@media screen and (max-width: 768px) {
  .about-wrapper .about-container .about-inner .about-inner-item {
    border-radius: 15px;
  }
}
.about-wrapper .about-container .about-inner .about-inner-item .about-item-inner {
  padding: 1.2rem;
}
@media screen and (max-width: 1200px) {
  .about-wrapper .about-container .about-inner .about-inner-item .about-item-inner {
    padding: 1rem;
  }
}
.about-wrapper .about-container .about-inner .about-inner-item .about-item-inner .about-inner-pic-cover {
  margin-bottom: 0.6rem;
}
@media screen and (max-width: 1200px) {
  .about-wrapper .about-container .about-inner .about-inner-item .about-item-inner .about-inner-pic-cover {
    margin-bottom: 0.6rem;
  }
}
@media screen and (max-width: 768px) {
  .about-wrapper .about-container .about-inner .about-inner-item .about-item-inner .about-inner-pic-cover {
    margin-bottom: 0.4rem;
  }
}
.about-wrapper .about-container .about-inner .about-inner-item .about-item-inner .about-inner-item-bottom {
  background: #fff;
  border-radius: 6px;
  padding: 0.8rem 1.5rem;
  height: 200px;
}
@media screen and (max-width: 1530px) {
  .about-wrapper .about-container .about-inner .about-inner-item .about-item-inner .about-inner-item-bottom {
    padding: 0.8rem 1.2rem;
    height: 160px;
  }
}
@media screen and (max-width: 1200px) {
  .about-wrapper .about-container .about-inner .about-inner-item .about-item-inner .about-inner-item-bottom {
    padding: 0.8rem;
    height: 150px;
  }
}
@media screen and (max-width: 768px) {
  .about-wrapper .about-container .about-inner .about-inner-item .about-item-inner .about-inner-item-bottom {
    padding: 0.6rem;
    height: 145px;
  }
}
.about-wrapper .about-container .about-inner .about-inner-item .about-item-inner .about-inner-item-bottom .about-inner-item-head {
  font-family: "co-headline", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1.8rem;
  font-style: italic;
  letter-spacing: 2px;
  text-align: left;
  margin-bottom: 0.5rem;
}
@media screen and (max-width: 1200px) {
  .about-wrapper .about-container .about-inner .about-inner-item .about-item-inner .about-inner-item-bottom .about-inner-item-head {
    font-size: 1.2rem;
    letter-spacing: 1px;
  }
}
@media screen and (max-width: 768px) {
  .about-wrapper .about-container .about-inner .about-inner-item .about-item-inner .about-inner-item-bottom .about-inner-item-head {
    font-size: 1rem;
  }
}
.about-wrapper .about-container .about-inner .about-inner-item .about-item-inner .about-inner-item-bottom .pink {
  color: #f4599e;
}
.about-wrapper .about-container .about-inner .about-inner-item .about-item-inner .about-inner-item-bottom .skyblue {
  color: #00a8ff;
}
.about-wrapper .about-container .about-inner .about-inner-item .about-item-inner .about-inner-item-bottom .purple {
  color: #984cf6;
}
.about-wrapper .about-container .about-inner .about-inner-item .about-item-inner .about-inner-item-bottom .about-inner-item-content {
  font-family: "Hiragino Sans", "Hiragino Kaku Gothic ProN", "MS Gothic", "Meiryo", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
  letter-spacing: 2px;
  text-align: justify;
  line-height: 1.5;
}
@media screen and (max-width: 1530px) {
  .about-wrapper .about-container .about-inner .about-inner-item .about-item-inner .about-inner-item-bottom .about-inner-item-content {
    font-size: 0.8rem;
    letter-spacing: 1px;
  }
}
@media screen and (max-width: 768px) {
  .about-wrapper .about-container .about-inner .about-inner-item .about-item-inner .about-inner-item-bottom .about-inner-item-content {
    font-size: 0.6rem;
  }
}
.about-wrapper .about-container .about-inner .about-inner-item .about-item-inner .about-inner-item-bottom .about-inner-item-content-third {
  font-size: 0.9rem;
}
@media screen and (max-width: 1530px) {
  .about-wrapper .about-container .about-inner .about-inner-item .about-item-inner .about-inner-item-bottom .about-inner-item-content-third {
    font-size: 0.7rem;
  }
}
@media screen and (max-width: 768px) {
  .about-wrapper .about-container .about-inner .about-inner-item .about-item-inner .about-inner-item-bottom .about-inner-item-content-third {
    font-size: 0.5rem;
  }
}
.about-wrapper .about-container .swiper-box {
  position: relative;
}
.about-wrapper .about-container .swiper-box .swiper {
  width: 335px;
  height: 410px;
  display: none;
}
@media screen and (max-width: 420px) {
  .about-wrapper .about-container .swiper-box .swiper {
    display: block;
  }
}
.about-wrapper .about-container .swiper-box .swiper .about-inner-item {
  background: #353535;
  border-radius: 30px;
  flex-basis: 50%;
}
@media screen and (max-width: 768px) {
  .about-wrapper .about-container .swiper-box .swiper .about-inner-item {
    border-radius: 15px;
  }
}
.about-wrapper .about-container .swiper-box .swiper .about-inner-item .about-item-inner {
  padding: 2rem;
}
@media screen and (max-width: 1200px) {
  .about-wrapper .about-container .swiper-box .swiper .about-inner-item .about-item-inner {
    padding: 1.5rem;
  }
}
@media screen and (max-width: 768px) {
  .about-wrapper .about-container .swiper-box .swiper .about-inner-item .about-item-inner {
    padding: 1rem;
  }
}
.about-wrapper .about-container .swiper-box .swiper .about-inner-item .about-item-inner .about-inner-pic-cover {
  margin-bottom: 1rem;
}
@media screen and (max-width: 1200px) {
  .about-wrapper .about-container .swiper-box .swiper .about-inner-item .about-item-inner .about-inner-pic-cover {
    margin-bottom: 0.6rem;
  }
}
@media screen and (max-width: 768px) {
  .about-wrapper .about-container .swiper-box .swiper .about-inner-item .about-item-inner .about-inner-pic-cover {
    margin-bottom: 0.4rem;
  }
}
.about-wrapper .about-container .swiper-box .swiper .about-inner-item .about-item-inner .about-inner-item-bottom {
  background: #fff;
  border-radius: 6px;
  padding: 0.6rem;
  height: 115px;
}
.about-wrapper .about-container .swiper-box .swiper .about-inner-item .about-item-inner .about-inner-item-bottom .about-inner-item-head {
  font-family: "co-headline", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1.8rem;
  font-style: italic;
  letter-spacing: 2px;
  text-align: left;
  margin-bottom: 0.5rem;
}
@media screen and (max-width: 1200px) {
  .about-wrapper .about-container .swiper-box .swiper .about-inner-item .about-item-inner .about-inner-item-bottom .about-inner-item-head {
    font-size: 1.2rem;
    letter-spacing: 1px;
  }
}
@media screen and (max-width: 768px) {
  .about-wrapper .about-container .swiper-box .swiper .about-inner-item .about-item-inner .about-inner-item-bottom .about-inner-item-head {
    font-size: 1rem;
  }
}
@media screen and (max-width: 420px) {
  .about-wrapper .about-container .swiper-box .swiper .about-inner-item .about-item-inner .about-inner-item-bottom .about-inner-item-head {
    font-size: 1.5rem;
  }
}
.about-wrapper .about-container .swiper-box .swiper .about-inner-item .about-item-inner .about-inner-item-bottom .pink {
  color: #f4599e;
}
.about-wrapper .about-container .swiper-box .swiper .about-inner-item .about-item-inner .about-inner-item-bottom .skyblue {
  color: #00a8ff;
}
.about-wrapper .about-container .swiper-box .swiper .about-inner-item .about-item-inner .about-inner-item-bottom .purple {
  color: #984cf6;
}
.about-wrapper .about-container .swiper-box .swiper .about-inner-item .about-item-inner .about-inner-item-bottom .about-inner-item-content {
  font-family: "Hiragino Sans", "Hiragino Kaku Gothic ProN", "MS Gothic", "Meiryo", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
  letter-spacing: 2px;
  text-align: justify;
  line-height: 1.5;
}
@media screen and (max-width: 1200px) {
  .about-wrapper .about-container .swiper-box .swiper .about-inner-item .about-item-inner .about-inner-item-bottom .about-inner-item-content {
    font-size: 0.8rem;
    letter-spacing: 1px;
  }
}
@media screen and (max-width: 768px) {
  .about-wrapper .about-container .swiper-box .swiper .about-inner-item .about-item-inner .about-inner-item-bottom .about-inner-item-content {
    font-size: 0.6rem;
  }
}
@media screen and (max-width: 420px) {
  .about-wrapper .about-container .swiper-box .swiper .about-inner-item .about-item-inner .about-inner-item-bottom .about-inner-item-content {
    font-size: 0.8rem;
  }
}
.about-wrapper .about-container .swiper-box .swiper .about-inner-item .about-item-inner .about-inner-item-bottom .about-inner-item-content-third {
  font-size: 0.6rem;
}
.about-wrapper .about-container .swiper-box .swiper-button-next, .about-wrapper .about-container .swiper-box .swiper-button-prev {
  display: none;
  top: var(--swiper-navigation-top-offset, 50%);
}
@media screen and (max-width: 420px) {
  .about-wrapper .about-container .swiper-box .swiper-button-next, .about-wrapper .about-container .swiper-box .swiper-button-prev {
    display: flex;
  }
}
.about-wrapper .about-container .swiper-box .swiper-button-prev {
  left: var(--swiper-navigation-sides-offset, -15px);
}
.about-wrapper .about-container .swiper-box .swiper-button-next {
  right: var(--swiper-navigation-sides-offset, -15px);
}
.about-wrapper .about-container .about-bottom-content {
  font-family: "Hiragino Sans", "Hiragino Kaku Gothic ProN", "MS Gothic", "Meiryo", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.2rem;
  letter-spacing: 2px;
  padding: 10rem 0 4rem;
}
@media screen and (max-width: 1530px) {
  .about-wrapper .about-container .about-bottom-content {
    padding: 6rem 0 2rem;
  }
}
@media screen and (max-width: 1200px) {
  .about-wrapper .about-container .about-bottom-content {
    font-size: 1rem;
    padding: 7rem 0 3rem;
  }
}
@media screen and (max-width: 768px) {
  .about-wrapper .about-container .about-bottom-content {
    font-size: 0.8rem;
    padding: 5rem 0 1rem;
  }
}
@media screen and (max-width: 420px) {
  .about-wrapper .about-container .about-bottom-content {
    padding: 2rem 0 1rem;
    line-height: 2.5;
  }
}

.strength-wrapper {
  width: 100%;
  height: auto;
}
.strength-wrapper .strength-container {
  max-width: 70%;
  margin: 0 auto;
  text-align: center;
  padding: 12rem 0;
}
@media screen and (max-width: 1530px) {
  .strength-wrapper .strength-container {
    padding: 8rem 0;
  }
}
@media screen and (max-width: 1200px) {
  .strength-wrapper .strength-container {
    max-width: 90%;
  }
}
@media screen and (max-width: 768px) {
  .strength-wrapper .strength-container {
    padding: 6rem 0;
  }
}
.strength-wrapper .strength-container .strength-head {
  font-family: "Hiragino Sans", "Hiragino Kaku Gothic ProN", "MS Gothic", "Meiryo", sans-serif;
  font-weight: bold;
  font-style: normal;
  font-size: 1.6rem;
  letter-spacing: 3px;
  margin-bottom: 5rem;
}
@media screen and (max-width: 1200px) {
  .strength-wrapper .strength-container .strength-head {
    font-size: 1.5rem;
    letter-spacing: 1px;
  }
}
@media screen and (max-width: 768px) {
  .strength-wrapper .strength-container .strength-head {
    font-size: 1.2rem;
    margin-bottom: 4rem;
  }
}
@media screen and (max-width: 420px) {
  .strength-wrapper .strength-container .strength-head {
    line-height: 2;
    margin-bottom: 3rem;
  }
}
.strength-wrapper .strength-container .strength-content {
  font-family: "Hiragino Sans", "Hiragino Kaku Gothic ProN", "MS Gothic", "Meiryo", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
  letter-spacing: 2px;
  line-height: 2.5;
  text-align: justify;
  margin-bottom: 6rem;
}
@media screen and (max-width: 1200px) {
  .strength-wrapper .strength-container .strength-content {
    font-size: 1rem;
    letter-spacing: 2px;
  }
}
@media screen and (max-width: 768px) {
  .strength-wrapper .strength-container .strength-content {
    font-size: 0.8rem;
    margin-bottom: 4rem;
  }
}
.strength-wrapper .strength-container .strength-pic-inner {
  width: 90%;
  margin: 0 auto;
}
@media screen and (max-width: 420px) {
  .strength-wrapper .strength-container .strength-pic-inner {
    width: 100%;
  }
}

.mv-wrapper {
  width: 100%;
  height: auto;
  background: #fafafa;
}
.mv-wrapper .mv-container {
  max-width: 70%;
  margin: 0 auto;
  text-align: center;
  padding: 12rem 0 8rem;
}
@media screen and (max-width: 1530px) {
  .mv-wrapper .mv-container {
    padding: 8rem 0 2rem;
  }
}
@media screen and (max-width: 1200px) {
  .mv-wrapper .mv-container {
    max-width: 90%;
  }
}
@media screen and (max-width: 768px) {
  .mv-wrapper .mv-container {
    padding: 6rem 0 4rem;
  }
}
@media screen and (max-width: 420px) {
  .mv-wrapper .mv-container {
    padding: 6rem 0 2rem;
  }
}
.mv-wrapper .mv-container .mv-head {
  font-family: "Hiragino Sans", "Hiragino Kaku Gothic ProN", "MS Gothic", "Meiryo", sans-serif;
  font-weight: bold;
  font-style: normal;
  font-size: 1.6rem;
  letter-spacing: 3px;
  margin-bottom: 3rem;
  margin-top: 6rem;
}
@media screen and (max-width: 1200px) {
  .mv-wrapper .mv-container .mv-head {
    font-size: 1.5rem;
    letter-spacing: 1px;
  }
}
@media screen and (max-width: 768px) {
  .mv-wrapper .mv-container .mv-head {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 420px) {
  .mv-wrapper .mv-container .mv-head {
    line-height: 2;
    margin-top: 3rem;
  }
}
.mv-wrapper .mv-container .mv-content {
  font-family: "Hiragino Sans", "Hiragino Kaku Gothic ProN", "MS Gothic", "Meiryo", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
  letter-spacing: 2px;
  line-height: 2.5;
  text-align: justify;
  margin-bottom: 6rem;
}
@media screen and (max-width: 1200px) {
  .mv-wrapper .mv-container .mv-content {
    font-size: 1rem;
    letter-spacing: 2px;
  }
}
@media screen and (max-width: 768px) {
  .mv-wrapper .mv-container .mv-content {
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 420px) {
  .mv-wrapper .mv-container .mv-content {
    margin-bottom: 3rem;
  }
}

.place-wrapper {
  width: 100%;
  height: auto;
}
.place-wrapper .place-container {
  max-width: 70%;
  margin: 0 auto;
  text-align: center;
  padding: 10rem 0;
}
@media screen and (max-width: 1200px) {
  .place-wrapper .place-container {
    max-width: 90%;
  }
}
@media screen and (max-width: 768px) {
  .place-wrapper .place-container {
    padding: 6rem 0;
  }
}
.place-wrapper .place-container .place-head {
  font-family: "Hiragino Sans", "Hiragino Kaku Gothic ProN", "MS Gothic", "Meiryo", sans-serif;
  font-weight: bold;
  font-style: normal;
  font-size: 2rem;
  letter-spacing: 3px;
  margin-bottom: 5rem;
  line-height: 2;
}
@media screen and (max-width: 1200px) {
  .place-wrapper .place-container .place-head {
    font-size: 1.7rem;
    margin-bottom: 4rem;
  }
}
@media screen and (max-width: 768px) {
  .place-wrapper .place-container .place-head {
    font-size: 1.4rem;
    letter-spacing: 2px;
  }
}
@media screen and (max-width: 420px) {
  .place-wrapper .place-container .place-head {
    font-size: 1.4rem;
    margin-bottom: 3rem;
  }
}
.place-wrapper .place-container .place-subhead {
  font-family: "Hiragino Sans", "Hiragino Kaku Gothic ProN", "MS Gothic", "Meiryo", sans-serif;
  font-weight: bold;
  font-style: normal;
  font-size: 1.7rem;
  letter-spacing: 3px;
  margin-bottom: 5rem;
  background: linear-gradient(350deg, #2c8ec4 45%, #31cdb0 52%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media screen and (max-width: 1200px) {
  .place-wrapper .place-container .place-subhead {
    font-size: 1.5rem;
    margin-bottom: 4rem;
  }
}
@media screen and (max-width: 768px) {
  .place-wrapper .place-container .place-subhead {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 420px) {
  .place-wrapper .place-container .place-subhead {
    font-size: 1.1rem;
    letter-spacing: 1.5px;
    margin-bottom: 3rem;
  }
}
.place-wrapper .place-container .place-content {
  font-family: "Hiragino Sans", "Hiragino Kaku Gothic ProN", "MS Gothic", "Meiryo", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
  letter-spacing: 2px;
  line-height: 2.5;
  text-align: justify;
  margin-bottom: 4rem;
}
@media screen and (max-width: 1200px) {
  .place-wrapper .place-container .place-content {
    font-size: 1rem;
    letter-spacing: 2px;
  }
}
@media screen and (max-width: 768px) {
  .place-wrapper .place-container .place-content {
    font-size: 0.8rem;
    margin-bottom: 4rem;
  }
}
@media screen and (max-width: 420px) {
  .place-wrapper .place-container .place-content {
    margin-bottom: 3rem;
    letter-spacing: 1px;
  }
}
.place-wrapper .place-container .place-inner {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 0 auto 4rem;
  width: 80%;
}
@media screen and (max-width: 420px) {
  .place-wrapper .place-container .place-inner {
    margin-bottom: 3rem;
    width: 100%;
  }
}
.place-wrapper .place-container .place-bottom-content {
  font-family: "Hiragino Sans", "Hiragino Kaku Gothic ProN", "MS Gothic", "Meiryo", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
  letter-spacing: 2px;
  line-height: 2.5;
  text-align: justify;
  margin-bottom: 5rem;
}
@media screen and (max-width: 1200px) {
  .place-wrapper .place-container .place-bottom-content {
    font-size: 1rem;
    letter-spacing: 2px;
  }
}
@media screen and (max-width: 768px) {
  .place-wrapper .place-container .place-bottom-content {
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 420px) {
  .place-wrapper .place-container .place-bottom-content {
    margin-bottom: 3rem;
  }
}
.place-wrapper .place-container .place-bottom-pic {
  width: 80%;
}
@media screen and (max-width: 420px) {
  .place-wrapper .place-container .place-bottom-pic {
    width: 100%;
  }
}

.ad-wrapper {
  width: 100%;
  height: auto;
  background: url("../../dest/img/ad-background.png") center/cover no-repeat;
}
.ad-wrapper .ad-container {
  max-width: 70%;
  margin: 0 auto;
  text-align: center;
  padding: 8rem 0 6rem;
}
@media screen and (max-width: 1200px) {
  .ad-wrapper .ad-container {
    max-width: 90%;
    padding: 8rem 0;
  }
}
@media screen and (max-width: 768px) {
  .ad-wrapper .ad-container {
    padding: 8rem 0 4rem;
  }
}
@media screen and (max-width: 420px) {
  .ad-wrapper .ad-container {
    padding: 6rem 0 3rem;
  }
}
.ad-wrapper .ad-container .ad-head {
  font-family: "Hiragino Sans", "Hiragino Kaku Gothic ProN", "MS Gothic", "Meiryo", sans-serif;
  font-weight: bold;
  font-style: normal;
  font-size: 1.8rem;
  letter-spacing: 3px;
  margin-bottom: 4rem;
  line-height: 2;
  background: linear-gradient(90deg, #f6bf75 15%, #d77185, #8766ac, #4150b1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media screen and (max-width: 1200px) {
  .ad-wrapper .ad-container .ad-head {
    font-size: 1.5rem;
    letter-spacing: 1px;
    margin-bottom: 4rem;
  }
}
@media screen and (max-width: 768px) {
  .ad-wrapper .ad-container .ad-head {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 420px) {
  .ad-wrapper .ad-container .ad-head {
    margin-bottom: 3rem;
  }
}
.ad-wrapper .ad-container .ad-head .ad-head-deco {
  font-size: 2.6rem;
  letter-spacing: 8px;
}
@media screen and (max-width: 1200px) {
  .ad-wrapper .ad-container .ad-head .ad-head-deco {
    font-size: 2rem;
    letter-spacing: 8px;
  }
}
@media screen and (max-width: 768px) {
  .ad-wrapper .ad-container .ad-head .ad-head-deco {
    font-size: 1.8rem;
    letter-spacing: 6px;
  }
}
@media screen and (max-width: 420px) {
  .ad-wrapper .ad-container .ad-head .ad-head-deco {
    font-size: 1.6rem;
    letter-spacing: 2px;
  }
}
.ad-wrapper .ad-container .ad-content {
  font-family: "Hiragino Sans", "Hiragino Kaku Gothic ProN", "MS Gothic", "Meiryo", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
  letter-spacing: 2px;
  line-height: 2.5;
  text-align: justify;
  margin-bottom: 5rem;
}
@media screen and (max-width: 1200px) {
  .ad-wrapper .ad-container .ad-content {
    font-size: 1rem;
    letter-spacing: 2px;
    margin-bottom: 4rem;
  }
}
@media screen and (max-width: 768px) {
  .ad-wrapper .ad-container .ad-content {
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 420px) {
  .ad-wrapper .ad-container .ad-content {
    margin-bottom: 3rem;
    letter-spacing: 1px;
  }
}
.ad-wrapper .ad-container .ad-inner-wrap {
  margin: 8rem auto;
}
@media screen and (max-width: 1200px) {
  .ad-wrapper .ad-container .ad-inner-wrap {
    margin: 6rem auto;
  }
}
@media screen and (max-width: 420px) {
  .ad-wrapper .ad-container .ad-inner-wrap {
    margin: 4rem auto 3rem;
  }
}
.ad-wrapper .ad-container .ad-inner-wrap .ad-inner {
  text-align: left;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 20px;
  margin: 6rem auto;
  padding: 2rem 2rem 4rem;
  box-shadow: 12px 12px 12px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}
@media screen and (max-width: 1200px) {
  .ad-wrapper .ad-container .ad-inner-wrap .ad-inner {
    margin: 4rem auto;
  }
}
@media screen and (max-width: 420px) {
  .ad-wrapper .ad-container .ad-inner-wrap .ad-inner {
    padding: 1rem 1rem 3rem;
    margin: 3rem auto;
  }
}
.ad-wrapper .ad-container .ad-inner-wrap .ad-inner .ad-inner-head {
  font-family: "Hiragino Sans", "Hiragino Kaku Gothic ProN", "MS Gothic", "Meiryo", sans-serif;
  font-weight: bold;
  font-style: italic;
  font-size: 2rem;
  letter-spacing: 3px;
  margin-bottom: 0.5rem;
}
@media screen and (max-width: 1200px) {
  .ad-wrapper .ad-container .ad-inner-wrap .ad-inner .ad-inner-head {
    font-size: 1.7rem;
    letter-spacing: 2px;
  }
}
@media screen and (max-width: 768px) {
  .ad-wrapper .ad-container .ad-inner-wrap .ad-inner .ad-inner-head {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 420px) {
  .ad-wrapper .ad-container .ad-inner-wrap .ad-inner .ad-inner-head {
    font-size: 1.2rem;
  }
}
.ad-wrapper .ad-container .ad-inner-wrap .ad-inner .ad-inner-head .ad-inner-deco {
  font-size: 2.6rem;
}
@media screen and (max-width: 1200px) {
  .ad-wrapper .ad-container .ad-inner-wrap .ad-inner .ad-inner-head .ad-inner-deco {
    font-size: 2rem;
    letter-spacing: 2px;
  }
}
@media screen and (max-width: 768px) {
  .ad-wrapper .ad-container .ad-inner-wrap .ad-inner .ad-inner-head .ad-inner-deco {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 420px) {
  .ad-wrapper .ad-container .ad-inner-wrap .ad-inner .ad-inner-head .ad-inner-deco {
    font-size: 1.4rem;
  }
}
.ad-wrapper .ad-container .ad-inner-wrap .ad-inner .ad-inner-content {
  font-family: "Hiragino Sans", "Hiragino Kaku Gothic ProN", "MS Gothic", "Meiryo", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
  letter-spacing: 2px;
  line-height: 2.5;
}
@media screen and (max-width: 1200px) {
  .ad-wrapper .ad-container .ad-inner-wrap .ad-inner .ad-inner-content {
    font-size: 1rem;
    letter-spacing: 2px;
  }
}
@media screen and (max-width: 768px) {
  .ad-wrapper .ad-container .ad-inner-wrap .ad-inner .ad-inner-content {
    font-size: 0.8rem;
  }
}
.ad-wrapper .ad-container .ad-inner-wrap .ad-inner-first:after {
  content: url("../../dest/img/ad-inner-head-1.png");
  position: absolute;
  top: -60px;
  right: -5px;
  transform: scale(1);
}
@media screen and (max-width: 1530px) {
  .ad-wrapper .ad-container .ad-inner-wrap .ad-inner-first:after {
    right: -62px;
    transform: scale(0.8);
  }
}
@media screen and (max-width: 1200px) {
  .ad-wrapper .ad-container .ad-inner-wrap .ad-inner-first:after {
    top: -50px;
    right: 0;
    transform: scale(1);
  }
}
@media screen and (max-width: 768px) {
  .ad-wrapper .ad-container .ad-inner-wrap .ad-inner-first:after {
    right: -90px;
    transform: scale(0.7);
  }
}
@media screen and (max-width: 420px) {
  .ad-wrapper .ad-container .ad-inner-wrap .ad-inner-first:after {
    top: -72px;
    right: -178px;
    transform: scale(0.4);
  }
}
.ad-wrapper .ad-container .ad-inner-wrap .ad-inner-second:after {
  content: url("../../dest/img/ad-inner-head-2.png");
  position: absolute;
  top: -60px;
  right: -5px;
  transform: scale(1);
}
@media screen and (max-width: 1530px) {
  .ad-wrapper .ad-container .ad-inner-wrap .ad-inner-second:after {
    right: -62px;
    transform: scale(0.8);
  }
}
@media screen and (max-width: 1200px) {
  .ad-wrapper .ad-container .ad-inner-wrap .ad-inner-second:after {
    top: -50px;
    right: 0;
    transform: scale(1);
  }
}
@media screen and (max-width: 768px) {
  .ad-wrapper .ad-container .ad-inner-wrap .ad-inner-second:after {
    right: -90px;
    transform: scale(0.7);
  }
}
@media screen and (max-width: 420px) {
  .ad-wrapper .ad-container .ad-inner-wrap .ad-inner-second:after {
    top: -72px;
    right: -178px;
    transform: scale(0.4);
  }
}
.ad-wrapper .ad-container .ad-inner-wrap .ad-inner-third:after {
  content: url("../../dest/img/ad-inner-head-3.png");
  position: absolute;
  top: -60px;
  right: -5px;
  transform: scale(1);
}
@media screen and (max-width: 1530px) {
  .ad-wrapper .ad-container .ad-inner-wrap .ad-inner-third:after {
    right: -62px;
    transform: scale(0.8);
  }
}
@media screen and (max-width: 1200px) {
  .ad-wrapper .ad-container .ad-inner-wrap .ad-inner-third:after {
    top: -50px;
    right: 0;
    transform: scale(1);
  }
}
@media screen and (max-width: 768px) {
  .ad-wrapper .ad-container .ad-inner-wrap .ad-inner-third:after {
    right: -90px;
    transform: scale(0.7);
  }
}
@media screen and (max-width: 420px) {
  .ad-wrapper .ad-container .ad-inner-wrap .ad-inner-third:after {
    top: -72px;
    right: -178px;
    transform: scale(0.4);
  }
}

.feture-wrapper {
  width: 100%;
  height: auto;
  background: #fafafa;
}
.feture-wrapper .feture-container {
  max-width: 70%;
  margin: 0 auto;
  text-align: center;
  padding: 8rem 0 6rem;
}
@media screen and (max-width: 1200px) {
  .feture-wrapper .feture-container {
    max-width: 90%;
    padding: 8rem 0;
  }
}
@media screen and (max-width: 420px) {
  .feture-wrapper .feture-container {
    padding: 6rem 0 3rem;
  }
}
.feture-wrapper .feture-container .feture-inner .feture-inner-head {
  font-family: "Hiragino Sans", "Hiragino Kaku Gothic ProN", "MS Gothic", "Meiryo", sans-serif;
  font-weight: bold;
  font-style: normal;
  font-size: 2rem;
  letter-spacing: 3px;
  margin-bottom: 4rem;
  background: linear-gradient(90deg, #f6bf75 25%, #d77185, #8766ac, #4150b1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media screen and (max-width: 1200px) {
  .feture-wrapper .feture-container .feture-inner .feture-inner-head {
    font-size: 1.7rem;
    letter-spacing: 2px;
  }
}
@media screen and (max-width: 768px) {
  .feture-wrapper .feture-container .feture-inner .feture-inner-head {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 420px) {
  .feture-wrapper .feture-container .feture-inner .feture-inner-head {
    margin-bottom: 2rem;
  }
}
.feture-wrapper .feture-container .feture-inner .feture-inner-content {
  font-family: "Hiragino Sans", "Hiragino Kaku Gothic ProN", "MS Gothic", "Meiryo", sans-serif;
  font-weight: bold;
  font-style: normal;
  font-size: 1.3rem;
  letter-spacing: 3px;
  line-height: 2;
  margin-bottom: 5rem;
}
@media screen and (max-width: 1200px) {
  .feture-wrapper .feture-container .feture-inner .feture-inner-content {
    font-size: 1.2rem;
    letter-spacing: 2px;
    margin-bottom: 4rem;
  }
}
@media screen and (max-width: 768px) {
  .feture-wrapper .feture-container .feture-inner .feture-inner-content {
    font-size: 1rem;
  }
}
@media screen and (max-width: 420px) {
  .feture-wrapper .feture-container .feture-inner .feture-inner-content {
    display: none;
  }
}
.feture-wrapper .feture-container .feture-inner .sp-feture-inner-content {
  font-family: "Hiragino Sans", "Hiragino Kaku Gothic ProN", "MS Gothic", "Meiryo", sans-serif;
  font-weight: bold;
  font-style: normal;
  font-size: 0.8rem;
  margin-bottom: 3rem;
  letter-spacing: 2px;
  line-height: 2;
  display: none;
}
@media screen and (max-width: 420px) {
  .feture-wrapper .feture-container .feture-inner .sp-feture-inner-content {
    display: block;
  }
}
.feture-wrapper .feture-container .feture-inner .feture-inner-pic {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5rem;
}
@media screen and (max-width: 1200px) {
  .feture-wrapper .feture-container .feture-inner .feture-inner-pic {
    margin-bottom: 4rem;
  }
}
@media screen and (max-width: 420px) {
  .feture-wrapper .feture-container .feture-inner .feture-inner-pic {
    flex-direction: column;
    gap: 1.5rem 0;
    margin-bottom: 3rem;
  }
}
.feture-wrapper .feture-container .feture-inner .feture-inner-pic .feture-pic {
  width: 50%;
  padding: 0 1.5rem;
}
@media screen and (max-width: 420px) {
  .feture-wrapper .feture-container .feture-inner .feture-inner-pic .feture-pic {
    width: 100%;
    padding: 0;
  }
}
.feture-wrapper .feture-container .feture-inner .feture-bottom-content {
  font-family: "Hiragino Sans", "Hiragino Kaku Gothic ProN", "MS Gothic", "Meiryo", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
  letter-spacing: 2px;
  line-height: 2.5;
}
@media screen and (max-width: 1200px) {
  .feture-wrapper .feture-container .feture-inner .feture-bottom-content {
    font-size: 1rem;
    letter-spacing: 2px;
  }
}
@media screen and (max-width: 768px) {
  .feture-wrapper .feture-container .feture-inner .feture-bottom-content {
    font-size: 0.8rem;
    letter-spacing: 1px;
  }
}
.feture-wrapper .feture-container .feture-inner-top {
  margin-bottom: 15rem;
}
@media screen and (max-width: 768px) {
  .feture-wrapper .feture-container .feture-inner-top {
    margin-bottom: 12rem;
  }
}
@media screen and (max-width: 420px) {
  .feture-wrapper .feture-container .feture-inner-top {
    margin-bottom: 8rem;
  }
}

.com-about-wrapper {
  width: 100%;
  height: auto;
  background: url("../../dest/img/material-33.png") center/cover no-repeat;
}
.com-about-wrapper .com-about-container {
  max-width: 70%;
  margin: 0 auto;
  text-align: center;
  padding: 8rem 0;
}
@media screen and (max-width: 1200px) {
  .com-about-wrapper .com-about-container {
    max-width: 90%;
    padding: 8rem 0;
  }
}
@media screen and (max-width: 420px) {
  .com-about-wrapper .com-about-container {
    padding: 4rem 0 3rem;
  }
}
.com-about-wrapper .com-about-container .com-about-head {
  font-family: "Hiragino Sans", "Hiragino Kaku Gothic ProN", "MS Gothic", "Meiryo", sans-serif;
  font-weight: bold;
  font-style: normal;
  font-size: 2rem;
  letter-spacing: 3px;
  margin-bottom: 6rem;
  line-height: 2;
}
@media screen and (max-width: 1200px) {
  .com-about-wrapper .com-about-container .com-about-head {
    font-size: 1.5rem;
    letter-spacing: 2px;
    margin-bottom: 4rem;
  }
}
@media screen and (max-width: 768px) {
  .com-about-wrapper .com-about-container .com-about-head {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 420px) {
  .com-about-wrapper .com-about-container .com-about-head {
    margin-bottom: 3rem;
  }
}
.com-about-wrapper .com-about-container .com-about-inner {
  text-align: left;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  margin: 6rem auto;
  padding: 8rem 5rem 1rem;
  box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.1);
}
@media screen and (max-width: 1530px) {
  .com-about-wrapper .com-about-container .com-about-inner {
    margin: 4rem auto;
    padding: 8rem 3rem 1rem;
  }
}
@media screen and (max-width: 768px) {
  .com-about-wrapper .com-about-container .com-about-inner {
    padding: 8rem 2.2rem 1rem;
  }
}
@media screen and (max-width: 420px) {
  .com-about-wrapper .com-about-container .com-about-inner {
    padding: 4rem 2rem 1rem;
  }
}
.com-about-wrapper .com-about-container .com-about-inner .com-about-contents {
  margin-bottom: 6rem;
  position: relative;
}
@media screen and (max-width: 768px) {
  .com-about-wrapper .com-about-container .com-about-inner .com-about-contents {
    margin-bottom: 4rem;
  }
}
@media screen and (max-width: 420px) {
  .com-about-wrapper .com-about-container .com-about-inner .com-about-contents {
    margin-bottom: 3rem;
  }
}
.com-about-wrapper .com-about-container .com-about-inner .com-about-contents .com-about-contents-head {
  font-family: "Hiragino Sans", "Hiragino Kaku Gothic ProN", "MS Gothic", "Meiryo", sans-serif;
  font-weight: bold;
  font-style: italic;
  font-size: 1.8rem;
  letter-spacing: 3px;
  margin-left: 3rem;
  margin-bottom: 1.2rem;
  line-height: 2;
  display: inline-block;
  background: linear-gradient(-50deg, #007cde, #01e9bd);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media screen and (max-width: 1200px) {
  .com-about-wrapper .com-about-container .com-about-inner .com-about-contents .com-about-contents-head {
    font-size: 1.5rem;
    letter-spacing: 2px;
    margin-bottom: 1.2rem;
  }
}
@media screen and (max-width: 768px) {
  .com-about-wrapper .com-about-container .com-about-inner .com-about-contents .com-about-contents-head {
    font-size: 1.4rem;
    margin-bottom: 1rem;
  }
}
@media screen and (max-width: 420px) {
  .com-about-wrapper .com-about-container .com-about-inner .com-about-contents .com-about-contents-head {
    font-size: 1.2rem;
    line-height: 1.5;
    margin-left: 1rem;
  }
}
.com-about-wrapper .com-about-container .com-about-inner .com-about-contents .com-about-contents-para {
  font-family: "Hiragino Sans", "Hiragino Kaku Gothic ProN", "MS Gothic", "Meiryo", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
  letter-spacing: 2px;
  line-height: 2.5;
}
@media screen and (max-width: 1200px) {
  .com-about-wrapper .com-about-container .com-about-inner .com-about-contents .com-about-contents-para {
    font-size: 1rem;
    letter-spacing: 2px;
  }
}
@media screen and (max-width: 768px) {
  .com-about-wrapper .com-about-container .com-about-inner .com-about-contents .com-about-contents-para {
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 420px) {
  .com-about-wrapper .com-about-container .com-about-inner .com-about-contents .com-about-contents-para {
    line-height: 2;
    letter-spacing: 1px;
    text-align: justify;
  }
}
.com-about-wrapper .com-about-container .com-about-inner .com-about-contents-first:before {
  content: url("../../dest/img/com-about-content-back-1.png");
  position: absolute;
  top: -55px;
  left: -40px;
  transform: scale(0.9);
}
@media screen and (max-width: 1200px) {
  .com-about-wrapper .com-about-container .com-about-inner .com-about-contents-first:before {
    top: -55px;
    left: -50px;
    transform: scale(0.8);
  }
}
@media screen and (max-width: 768px) {
  .com-about-wrapper .com-about-container .com-about-inner .com-about-contents-first:before {
    left: -65px;
    transform: scale(0.7);
  }
}
@media screen and (max-width: 420px) {
  .com-about-wrapper .com-about-container .com-about-inner .com-about-contents-first:before {
    left: -95px;
    transform: scale(0.5);
  }
}
.com-about-wrapper .com-about-container .com-about-inner .com-about-contents-second:before {
  content: url("../../dest/img/com-about-content-back-2.png");
  position: absolute;
  top: -55px;
  left: -40px;
  transform: scale(0.9);
}
@media screen and (max-width: 1200px) {
  .com-about-wrapper .com-about-container .com-about-inner .com-about-contents-second:before {
    top: -55px;
    left: -50px;
    transform: scale(0.8);
  }
}
@media screen and (max-width: 768px) {
  .com-about-wrapper .com-about-container .com-about-inner .com-about-contents-second:before {
    left: -65px;
    transform: scale(0.7);
  }
}
@media screen and (max-width: 420px) {
  .com-about-wrapper .com-about-container .com-about-inner .com-about-contents-second:before {
    left: -95px;
    transform: scale(0.5);
  }
}
.com-about-wrapper .com-about-container .com-about-inner .com-about-contents-third:before {
  content: url("../../dest/img/com-about-content-back-3.png");
  position: absolute;
  top: -55px;
  left: -40px;
  transform: scale(0.9);
}
@media screen and (max-width: 1200px) {
  .com-about-wrapper .com-about-container .com-about-inner .com-about-contents-third:before {
    top: -55px;
    left: -50px;
    transform: scale(0.8);
  }
}
@media screen and (max-width: 768px) {
  .com-about-wrapper .com-about-container .com-about-inner .com-about-contents-third:before {
    left: -65px;
    transform: scale(0.7);
  }
}
@media screen and (max-width: 420px) {
  .com-about-wrapper .com-about-container .com-about-inner .com-about-contents-third:before {
    left: -95px;
    transform: scale(0.5);
  }
}
.com-about-wrapper .com-about-container .com-about-inner .com-about-contents-fourth:before {
  content: url("../../dest/img/com-about-content-back-4.png");
  position: absolute;
  top: -55px;
  left: -40px;
  transform: scale(0.9);
}
@media screen and (max-width: 1200px) {
  .com-about-wrapper .com-about-container .com-about-inner .com-about-contents-fourth:before {
    top: -55px;
    left: -50px;
    transform: scale(0.8);
  }
}
@media screen and (max-width: 768px) {
  .com-about-wrapper .com-about-container .com-about-inner .com-about-contents-fourth:before {
    left: -65px;
    transform: scale(0.7);
  }
}
@media screen and (max-width: 420px) {
  .com-about-wrapper .com-about-container .com-about-inner .com-about-contents-fourth:before {
    left: -95px;
    transform: scale(0.5);
  }
}

.apparel-wrapper {
  width: 100%;
  height: auto;
  background: url("../../dest/img/material-35.png") center/cover no-repeat;
}
.apparel-wrapper .apparel-container {
  max-width: 70%;
  margin: 0 auto;
  text-align: center;
  padding: 12rem 0;
}
@media screen and (max-width: 1200px) {
  .apparel-wrapper .apparel-container {
    max-width: 90%;
    padding: 6rem 0;
  }
}
.apparel-wrapper .apparel-container .apparel-head {
  font-family: new-hero, sans-serif;
  font-weight: 800;
  font-style: normal;
  font-style: normal;
  font-size: 2rem;
  letter-spacing: 3px;
  margin-bottom: 8rem;
}
@media screen and (max-width: 1200px) {
  .apparel-wrapper .apparel-container .apparel-head {
    font-size: 1.5rem;
    letter-spacing: 2px;
    margin-bottom: 8rem;
  }
}
@media screen and (max-width: 768px) {
  .apparel-wrapper .apparel-container .apparel-head {
    font-size: 1.4rem;
    margin-bottom: 4rem;
  }
}
@media screen and (max-width: 420px) {
  .apparel-wrapper .apparel-container .apparel-head {
    margin-bottom: 3rem;
  }
}
.apparel-wrapper .apparel-container .apparel-inner {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: start;
  gap: 2rem 1.5rem;
  margin-bottom: 8rem;
}
@media screen and (max-width: 1530px) {
  .apparel-wrapper .apparel-container .apparel-inner {
    gap: 1.5rem 1.2rem;
    margin-bottom: 7rem;
  }
}
@media screen and (max-width: 1200px) {
  .apparel-wrapper .apparel-container .apparel-inner {
    gap: 2rem 1.2rem;
    margin-bottom: 6rem;
  }
}
@media screen and (max-width: 768px) {
  .apparel-wrapper .apparel-container .apparel-inner {
    gap: 1rem 1.2rem;
  }
}
@media screen and (max-width: 420px) {
  .apparel-wrapper .apparel-container .apparel-inner {
    display: none;
  }
}
.apparel-wrapper .apparel-container .apparel-inner .apparel-inner-contents {
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 20px;
  padding: 2rem 3rem 5rem;
  box-shadow: 12px 12px 12px 6px rgba(0, 0, 0, 0.1);
  height: 100%;
}
@media screen and (max-width: 1530px) {
  .apparel-wrapper .apparel-container .apparel-inner .apparel-inner-contents {
    padding: 1rem 1.5rem 2rem;
  }
}
@media screen and (max-width: 1200px) {
  .apparel-wrapper .apparel-container .apparel-inner .apparel-inner-contents {
    padding: 1rem 1.5rem 3rem;
  }
}
@media screen and (max-width: 768px) {
  .apparel-wrapper .apparel-container .apparel-inner .apparel-inner-contents {
    padding: 0.6rem 1.2rem 2rem;
  }
}
.apparel-wrapper .apparel-container .apparel-inner .apparel-inner-contents .apparel-inner-contents-pic {
  height: 70%;
  object-fit: contain;
  margin-top: 2rem;
  margin-bottom: 3rem;
}
@media screen and (max-width: 1530px) {
  .apparel-wrapper .apparel-container .apparel-inner .apparel-inner-contents .apparel-inner-contents-pic {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
@media screen and (max-width: 1200px) {
  .apparel-wrapper .apparel-container .apparel-inner .apparel-inner-contents .apparel-inner-contents-pic {
    margin-bottom: 1.5rem;
  }
}
@media screen and (max-width: 768px) {
  .apparel-wrapper .apparel-container .apparel-inner .apparel-inner-contents .apparel-inner-contents-pic {
    margin-top: 1rem;
    margin-bottom: 0.8rem;
  }
}
.apparel-wrapper .apparel-container .apparel-inner .apparel-inner-contents .apparel-inner-contents-head {
  font-family: new-hero, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.2rem;
  letter-spacing: 2px;
  margin-bottom: 1rem;
}
@media screen and (max-width: 1530px) {
  .apparel-wrapper .apparel-container .apparel-inner .apparel-inner-contents .apparel-inner-contents-head {
    font-size: 1rem;
  }
}
@media screen and (max-width: 1200px) {
  .apparel-wrapper .apparel-container .apparel-inner .apparel-inner-contents .apparel-inner-contents-head {
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 768px) {
  .apparel-wrapper .apparel-container .apparel-inner .apparel-inner-contents .apparel-inner-contents-head {
    font-size: 0.6rem;
  }
}
.apparel-wrapper .apparel-container .apparel-inner .apparel-inner-contents .apparel-inner-contents-para {
  font-family: new-hero, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.2rem;
  letter-spacing: 2px;
}
@media screen and (max-width: 1530px) {
  .apparel-wrapper .apparel-container .apparel-inner .apparel-inner-contents .apparel-inner-contents-para {
    font-size: 1rem;
  }
}
@media screen and (max-width: 1200px) {
  .apparel-wrapper .apparel-container .apparel-inner .apparel-inner-contents .apparel-inner-contents-para {
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 768px) {
  .apparel-wrapper .apparel-container .apparel-inner .apparel-inner-contents .apparel-inner-contents-para {
    font-size: 0.6rem;
  }
}
.apparel-wrapper .apparel-container .swiper-box {
  position: relative;
}
.apparel-wrapper .apparel-container .swiper-box .swiper-apparel {
  width: 335px;
  height: 465px;
  display: none;
}
@media screen and (max-width: 420px) {
  .apparel-wrapper .apparel-container .swiper-box .swiper-apparel {
    display: block;
    margin-bottom: 4rem;
  }
}
.apparel-wrapper .apparel-container .swiper-box .swiper-apparel .swiper-wrapper {
  padding: 1rem 0;
}
.apparel-wrapper .apparel-container .swiper-box .swiper-apparel .swiper-wrapper .apparel-inner-contents {
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 20px;
  padding: 2rem 3rem 5rem;
  box-shadow: 1px 2px 10px 4px rgba(0, 0, 0, 0.1);
  height: 370px;
  width: 280px;
  margin: 0 auto;
}
@media screen and (max-width: 1200px) {
  .apparel-wrapper .apparel-container .swiper-box .swiper-apparel .swiper-wrapper .apparel-inner-contents {
    padding: 1rem 1.5rem 3rem;
  }
}
@media screen and (max-width: 768px) {
  .apparel-wrapper .apparel-container .swiper-box .swiper-apparel .swiper-wrapper .apparel-inner-contents {
    padding: 0.6rem 1.2rem 2rem;
  }
}
.apparel-wrapper .apparel-container .swiper-box .swiper-apparel .swiper-wrapper .apparel-inner-contents .apparel-inner-contents-pic {
  height: 70%;
  object-fit: contain;
  margin-top: 2rem;
  margin-bottom: 3rem;
}
@media screen and (max-width: 1200px) {
  .apparel-wrapper .apparel-container .swiper-box .swiper-apparel .swiper-wrapper .apparel-inner-contents .apparel-inner-contents-pic {
    margin-bottom: 1.5rem;
  }
}
@media screen and (max-width: 768px) {
  .apparel-wrapper .apparel-container .swiper-box .swiper-apparel .swiper-wrapper .apparel-inner-contents .apparel-inner-contents-pic {
    margin-top: 1rem;
    margin-bottom: 0.8rem;
  }
}
.apparel-wrapper .apparel-container .swiper-box .swiper-apparel .swiper-wrapper .apparel-inner-contents .apparel-inner-contents-head {
  font-family: new-hero, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
  letter-spacing: 1px;
  margin-bottom: 1rem;
}
.apparel-wrapper .apparel-container .swiper-box .swiper-apparel .swiper-wrapper .apparel-inner-contents .apparel-inner-contents-para {
  font-family: new-hero, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
  letter-spacing: 1px;
}
.apparel-wrapper .apparel-container .swiper-box .swiper-button-next, .apparel-wrapper .apparel-container .swiper-box .swiper-button-prev {
  display: none;
  top: var(--swiper-navigation-top-offset, 50%);
}
@media screen and (max-width: 420px) {
  .apparel-wrapper .apparel-container .swiper-box .swiper-button-next, .apparel-wrapper .apparel-container .swiper-box .swiper-button-prev {
    display: flex;
  }
}
.apparel-wrapper .apparel-container .swiper-box .swiper-button-prev {
  left: var(--swiper-navigation-sides-offset, -10px);
}
.apparel-wrapper .apparel-container .swiper-box .swiper-button-next {
  right: var(--swiper-navigation-sides-offset, -10px);
}
.apparel-wrapper .apparel-container .apparel-link-inner .apparel-link {
  font-family: "dunbar-low", sans-serif;
  font-weight: 200;
  font-style: normal;
  background: rgb(0, 0, 0);
  color: #fff;
  font-size: 1.6rem;
  letter-spacing: 1px;
  padding: 1rem 12rem;
  border-radius: 40px;
  cursor: pointer;
  transition: all 0.5s;
}
.apparel-wrapper .apparel-container .apparel-link-inner .apparel-link:hover {
  background: rgba(0, 0, 0, 0.6);
}
@media screen and (max-width: 1200px) {
  .apparel-wrapper .apparel-container .apparel-link-inner .apparel-link {
    font-size: 1.2rem;
    padding: 1rem 8rem;
  }
}
@media screen and (max-width: 768px) {
  .apparel-wrapper .apparel-container .apparel-link-inner .apparel-link {
    font-size: 1rem;
    padding: 1rem 6rem;
  }
}

.public-wrapper {
  width: 100%;
  height: auto;
}
.public-wrapper .public-container {
  max-width: 70%;
  margin: 0 auto;
  text-align: center;
  padding: 12rem 0;
}
@media screen and (max-width: 1200px) {
  .public-wrapper .public-container {
    max-width: 90%;
    padding: 8rem 0;
  }
}
.public-wrapper .public-container .public-head {
  font-family: "Hiragino Sans", "Hiragino Kaku Gothic ProN", "MS Gothic", "Meiryo", sans-serif;
  font-weight: bold;
  font-style: normal;
  font-size: 2rem;
  letter-spacing: 3px;
  margin-bottom: 6rem;
  line-height: 2;
}
@media screen and (max-width: 1200px) {
  .public-wrapper .public-container .public-head {
    font-size: 1.5rem;
    letter-spacing: 2px;
    margin-bottom: 4rem;
  }
}
@media screen and (max-width: 768px) {
  .public-wrapper .public-container .public-head {
    font-size: 1.4rem;
  }
}
.public-wrapper .public-container .public-inner {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4rem;
  width: 80%;
  margin: 0 auto;
}
@media screen and (max-width: 1200px) {
  .public-wrapper .public-container .public-inner {
    gap: 2rem;
  }
}
@media screen and (max-width: 768px) {
  .public-wrapper .public-container .public-inner {
    gap: 3rem;
  }
}
@media screen and (max-width: 420px) {
  .public-wrapper .public-container .public-inner {
    width: 100%;
    gap: 2rem;
  }
}
.public-wrapper .public-container .public-inner .public-inner-content .public-inner-pic {
  margin-bottom: 1.2rem;
}
.public-wrapper .public-container .public-inner .public-inner-content .public-inner-content-para {
  font-family: "Hiragino Sans", "Hiragino Kaku Gothic ProN", "MS Gothic", "Meiryo", sans-serif;
  font-weight: bold;
  font-style: normal;
  font-size: 1rem;
  letter-spacing: 1px;
  text-align: center;
  transform: translateX(10px);
}
@media screen and (max-width: 768px) {
  .public-wrapper .public-container .public-inner .public-inner-content .public-inner-content-para {
    font-size: 0.7rem;
  }
}
@media screen and (max-width: 420px) {
  .public-wrapper .public-container .public-inner .public-inner-content .public-inner-content-para {
    transform: translateX(0);
  }
}

footer {
  width: 100%;
  height: auto;
  background: url("../../dest/img/footer-background.png") top/cover no-repeat;
}
@media screen and (max-width: 420px) {
  footer {
    background: url("../../dest/img/footer-background.png") left/cover no-repeat;
  }
}
footer .footer-container {
  padding: 6rem 2rem 3rem;
}
@media screen and (max-width: 1200px) {
  footer .footer-container {
    padding: 5rem 1rem 2rem;
  }
}
@media screen and (max-width: 420px) {
  footer .footer-container {
    padding: 3rem 1rem 2rem;
  }
}
footer .footer-container .footer-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 22rem;
}
@media screen and (max-width: 1200px) {
  footer .footer-container .footer-inner {
    margin-bottom: 18rem;
  }
}
@media screen and (max-width: 420px) {
  footer .footer-container .footer-inner {
    flex-direction: column;
    align-items: start;
    margin-bottom: 8rem;
  }
}
footer .footer-container .footer-inner .footer-inner-left {
  width: 22%;
}
@media screen and (max-width: 420px) {
  footer .footer-container .footer-inner .footer-inner-left {
    width: 70%;
    margin-bottom: 1.5rem;
    transform: translateX(-12px);
  }
}
footer .footer-container .footer-inner .footer-inner-right {
  margin-right: 2rem;
}
@media screen and (max-width: 1200px) {
  footer .footer-container .footer-inner .footer-inner-right {
    margin-right: 1rem;
  }
}
@media screen and (max-width: 768px) {
  footer .footer-container .footer-inner .footer-inner-right {
    margin-right: 0;
  }
}
footer .footer-container .footer-inner .footer-inner-right .footer-inner-right-lists {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0 3rem;
}
@media screen and (max-width: 1200px) {
  footer .footer-container .footer-inner .footer-inner-right .footer-inner-right-lists {
    gap: 0 2rem;
  }
}
@media screen and (max-width: 768px) {
  footer .footer-container .footer-inner .footer-inner-right .footer-inner-right-lists {
    gap: 0 1rem;
  }
}
@media screen and (max-width: 420px) {
  footer .footer-container .footer-inner .footer-inner-right .footer-inner-right-lists {
    flex-direction: column;
    align-items: start;
  }
}
@media screen and (max-width: 420px) {
  footer .footer-container .footer-inner .footer-inner-right .footer-inner-right-lists .footer-inner-right-list {
    margin-bottom: 1rem;
  }
}
footer .footer-container .footer-inner .footer-inner-right .footer-inner-right-lists .footer-inner-right-list .footer-inner-right-link {
  font-family: "Hiragino Sans", "Hiragino Kaku Gothic ProN", "MS Gothic", "Meiryo", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
  letter-spacing: 2px;
}
@media screen and (max-width: 1200px) {
  footer .footer-container .footer-inner .footer-inner-right .footer-inner-right-lists .footer-inner-right-list .footer-inner-right-link {
    font-size: 1rem;
    letter-spacing: 1.5px;
  }
}
@media screen and (max-width: 768px) {
  footer .footer-container .footer-inner .footer-inner-right .footer-inner-right-lists .footer-inner-right-list .footer-inner-right-link {
    font-size: 0.6rem;
    letter-spacing: 1.2px;
  }
}
@media screen and (max-width: 420px) {
  footer .footer-container .footer-inner .footer-inner-right .footer-inner-right-lists .footer-inner-right-list .footer-inner-right-link {
    font-size: 0.8rem;
    letter-spacing: 1px;
  }
}
footer .footer-container .copywrite-inner {
  text-align: center;
}
footer .footer-container .copywrite-inner .copywrite {
  font-family: new-hero, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 0.8rem;
  letter-spacing: 2px;
}
@media screen and (max-width: 768px) {
  footer .footer-container .copywrite-inner .copywrite {
    font-size: 0.6rem;
    letter-spacing: 1px;
  }
}