@use "mixin" as mix;

ul {
    list-style: none;
}

a {
    text-decoration: none;
    color: mix.$mainFontColor;
}

img {
    width: 100%;
    height: auto;
}

h1,h2,h3,h4,p,span {
    color: mix.$mainFontColor;
}

.sp-br {
    display: none;

    @include mix.mq(sp) {
        display: block;
    }
}

.swiper-button-prev:after {
    content: ''!important;
    background: url("../../dest/img/arrow-prev.png") center/contain no-repeat;
    width: 50px;
    height: 50px;
}

.swiper-button-next:after {
    content: "" !important;
    background: url("../../dest/img/arrow-next.png") center/contain no-repeat;
    width: 50px;
    height: 50px;
}

.swiper-pagination-bullet-active {
    background: #00a8ff !important;
}

header {
    width: 100%;
    height: auto;
    position: fixed;
    z-index: 9998;
    transition: all .5s;

    .header-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 1.5rem;

        @include mix.mq(sp) {
            padding: 1rem;
        }

        .header-left {
            display: inline-block;
            z-index: 9998;
            width: 20%;
            height: auto;

            @include mix.mq(bg-tab) {
                width: 30%;
            }

            @include mix.mq(sp) {
                width: 55%;
                transform: translateX(-12px);
            }
        }

        .header-right {
            .nv {

                nav {
                    display: block;
                    position: fixed;
                    top: 0;
                    right: -300px;
                    bottom: 0;
                    width: 400px;
                    background: linear-gradient(180deg,#f6bf75 5%,#d77185,#8766ac,#4150b1);
                    overflow-x: hidden;
                    overflow-y: auto;
                    -webkit-overflow-scrolling: touch;
                    transition: all .5s;
                    z-index: 3;
                    opacity: 0;

                    &.open {
                        right: 0;
                        opacity: 1;
                    }

                    @include mix.mq(sp) {
                        right: -220px;
                        width: 185px;
                    }

                    .inner {
                        padding: 25px;

                        @include mix.mq(sp) {
                            padding: 15px;
                        }

                        .inner-lists {
                            margin: 7rem 0 0;
                            padding: 0;

                            @include mix.mq(bg-tab) {
                                margin: 10rem 0 0;
                            }

                            @include mix.mq(sp) {
                                margin: 8rem 0 0;
                            }

                            .inner-list {
                                position: relative;
                                margin: 0;
                                border-bottom: 1px solid #fff;

                                .inner-list-btn {
                                    @include mix.mainFont(400,normal);
                                    display: block;
                                    color: #fff;
                                    font-size: 1rem;
                                    padding: 2rem;
                                    transition-duration: .2s;
                                    letter-spacing: 2px;

                                    &:hover {
                                        background: #e4e4e4;
                                    }

                                    @include mix.mq(bg-tab) {
                                        font-size: 1.6rem;
                                        letter-spacing: 4px;
                                    }

                                    @include mix.mq(sp) {
                                        font-size: .8rem;
                                        letter-spacing: 1.5px;
                                        padding: 1.6rem 0.5rem;
                                    }
                                }
                            }
                        }
                    }
                }

                .toggle_btn {
                    display: block;
                    position: relative;
                    top: 3px;
                    right: 0;
                    width: 60px;
                    height: 30px;
                    transition: all .5s;
                    cursor: pointer;
                    z-index: 9999;

                    span {
                        display: block;
                        position: absolute;
                        left: 0;
                        width: 60px;
                        height: 4px;
                        background-color: #353535;
                        border-radius: 4px;
                        transition: all .5s;
                        
                        &:nth-child(1) {
                            top: 4px;
                        }

                        &:nth-child(2) {
                            top: 14px;
                        }

                        &.open {
                            &:nth-child(1) {
                                -webkit-transform: translateX(15px)  translateY(10px) rotate(-315deg);
                                transform: translateX(15px) translateY(10px) rotate(-315deg);
                                width: 30px;
                                background: #fff;
                            }

                            &:nth-child(2) {
                                -webkit-transform: translateX(15px)  translateY(-10px) rotate(315deg);
                                transform: translateX(15px)  translateY(0) rotate(315deg);
                                width: 30px;
                                background: #fff;
                            }
                        }
                    }
                }

                #mask {
                    display: none;
                    transition: all .5s;

                    &.open {
                        display: block;
                        position: fixed;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: #000;
                        opacity: .8;
                        z-index: 2;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

.scrolled {
    box-shadow: 2px 2px 4px 3px rgba(0,0,0,.2);
    background: linear-gradient(220deg, #f6bf75 5%, #d77185, #8766ac, #4150b1);
}

.hero {
    width: 100%;
    height: 100vh;
    background:url("../../dest/img/footer-background.png") center/cover no-repeat;
    overflow-x: clip;

    @include mix.mq(bg-tab) {
        height: 50vh;
    }

    @include mix.mq(sm-tab) {
        height: 60vh;
    }

    @include mix.mq(sp) {
        height: 100svh;
        background: url("../../dest/img/sp-hero-back.png") center/cover no-repeat;
    }

    .hero-container {
        padding: 7rem 5rem;

        @include mix.mq(sm-pc) {
            padding: 8rem 5rem;
        }

        @include mix.mq(bg-tab) {
            padding: 8rem 3rem;
        }

        @include mix.mq(sm-tab) {
            padding: 10rem 3rem;
        }

        @include mix.mq(sp) {
            padding: 10rem 1rem;
        }

        .hero-inner {
            display: flex;
            justify-content: space-between;
            align-items: center;

            @include mix.mq(sp) {
                flex-direction: column-reverse;
                align-items: start;
            }

            .hero-inner-left {

                @include mix.mq(sp) {
                    position: relative;
                    z-index: 2;
                    transform: translateY(-145px);
                }

                .hero-head {
                    @include mix.hero;
                    font-size: 7rem;
                    letter-spacing: 4px;
                    line-height: 1.1;
                    margin-bottom: 5rem;

                    @include mix.mq(sm-pc) {
                        font-size: 6rem;
                        margin-bottom: 3rem;
                    }

                    @include mix.mq(bg-tab) {
                        font-size: 4rem;
                        margin-bottom: 2rem;
                    }

                    @include mix.mq(sm-tab) {
                        font-size: 3rem;
                    }

                    @include mix.mq(sp) {
                        font-size: 2.8rem;
                        margin-bottom: 1rem;
                        letter-spacing: 1px;
                    }
                }

                .hero-content {
                    @include mix.mainFont(bold,normal);
                    font-size: 1.4rem;
                    letter-spacing: 2px;
                    line-height: 2.2;

                    @include mix.mq(sm-pc) {
                        font-size: 1.2rem;
                    }

                    @include mix.mq(bg-tab) {
                        font-size: 1rem;
                        white-space: nowrap;
                    }

                    @include mix.mq(sm-tab) {
                        font-size: .8rem;
                        letter-spacing: 1px;
                    }

                    @include mix.mq(sp) {
                        display: none;
                    }
                }

                .sp-hero-content {
                    @include mix.mainFont(bold,normal);
                    font-size: .9rem;
                    letter-spacing: .6px;
                    line-height: 1.8;
                    display: none;

                    @include mix.mq(sp) {
                        display: block;
                    }
                }

                .sp-hero-content-first {
                    margin-bottom: .5rem;
                }
            }

            .hero-inner-right {
                width: 50%;

                @include mix.mq(sm-tab) {
                    width: 40%;
                }

                @include mix.mq(sp) {
                    width: 80%;
                }

                .hero-pic {

                    @include mix.mq(sm-tab) {
                        transform: scale(1.2);
                    }

                    @include mix.mq(sp) {
                        transform: scale(1.6) translate(40px, -20px);
                    }
                }
            }
        }
    }
}

.about-wrapper {
    width: 100%;
    height: auto;
    background: url("../../dest/img/material-1.png") center/cover no-repeat;
    position: relative;
    z-index: 3;

    .about-container {
        text-align: center;
        max-width: 70%;
        margin: 0 auto;
        padding: 8rem 0;

        @include mix.mq(bg-tab) {
            max-width: 90%;
            padding: 6rem 0;
        }

        @include mix.mq(sp) {
            padding: 6rem 0 3rem;
        }

        .about-head {
            @include mix.mainFont(bold,normal);
            font-size: 1.6rem;
            letter-spacing: 2px;
            line-height: 2;
            margin-bottom: 6rem;
            position: relative;

            @include mix.mq(bg-tab) {
                font-size: 1.5rem;
                letter-spacing: 1px;
            }

            @include mix.mq(sm-tab) {
                font-size: 1.2rem;
                margin-bottom: 4rem;
            }

            @include mix.mq(sp) {
                z-index: 2;
                margin-bottom: 3rem;
            }

            &:after {
                content: url("../../dest/img/material-2.png");
                position: absolute;
                top: -615px;
                right: -385px;
                transform: scale(.16);

                @include mix.mq(sm-pc) {
                    top: -615px;
                    right: -450px;
                    transform: scale(.12);
                }

                @include mix.mq(sm-tab) {
                    top: -625px;
                    right: -465px;
                    transform: scale(.1);
                }

                @include mix.mq(sp) {
                    z-index: -1;
                }
            }
        }

        .about-inner {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 2rem;

            @include mix.mq(bg-tab) {
                gap: 1.4rem;
            }

            @include mix.mq(sm-tab) {
                gap: 1rem;
            }

            @include mix.mq(sp) {
                display: none;
            }

            .about-inner-item {
                background: mix.$mainFontColor;
                border-radius: 20px;
                flex-basis: 50%;

                @include mix.mq(sm-tab) {
                    border-radius: 15px;
                }

                .about-item-inner {
                    padding: 1.2rem;

                    @include mix.mq(bg-tab) {
                        padding: 1rem;
                    }

                    .about-inner-pic-cover {
                        margin-bottom: .6rem;

                        @include mix.mq(bg-tab) {
                            margin-bottom: .6rem;
                        }

                        @include mix.mq(sm-tab) {
                            margin-bottom: .4rem;
                        }
                    }

                    .about-inner-item-bottom {
                        background: #fff;
                        border-radius: 6px;
                        padding: .8rem 1.5rem;
                        height: 200px;

                        @include mix.mq(sm-pc) {
                            padding: .8rem 1.2rem;
                            height: 160px;
                        }

                        @include mix.mq(bg-tab) {
                            padding: .8rem;
                            height: 150px;
                        }

                        @include mix.mq(sm-tab) {
                            padding: .6rem;
                            height: 145px;
                        }

                        .about-inner-item-head {
                            @include mix.co;
                            font-size: 1.8rem;
                            font-style: italic;
                            letter-spacing: 2px;
                            text-align: left;
                            margin-bottom: .5rem;

                            @include mix.mq(bg-tab) {
                                font-size: 1.2rem;
                                letter-spacing: 1px;
                            }

                            @include mix.mq(sm-tab) {
                                font-size: 1rem;
                            }
                        }

                        .pink {
                            color: #f4599e;
                        }

                        .skyblue {
                            color: #00a8ff;
                        }

                        .purple {
                            color: #984cf6;
                        }

                        .about-inner-item-content {
                            @include mix.mainFont(400,normal);
                            font-size: 1rem;
                            letter-spacing: 2px;
                            text-align: justify;
                            line-height: 1.5;

                            @include mix.mq(sm-pc) {
                                font-size: .8rem;
                                letter-spacing: 1px;
                            }

                            @include mix.mq(sm-tab) {
                                font-size: .6rem;
                            }
                        }

                        .about-inner-item-content-third {
                            font-size: .9rem;

                            @include mix.mq(sm-pc) {
                                font-size: .7rem;
                            }

                            @include mix.mq(sm-tab) {
                                font-size: .5rem;
                            }
                        }
                    }   
                }
            }
        }

        .swiper-box {
            position: relative;

            .swiper {
                width: 335px;
                height: 410px;
                display: none;
    
                @include mix.mq(sp) {
                    display: block;
                }
    
                .about-inner-item {
                    background: mix.$mainFontColor;
                    border-radius: 30px;
                    flex-basis: 50%;
    
                    @include mix.mq(sm-tab) {
                        border-radius: 15px;
                    }
    
                    .about-item-inner {
                        padding: 2rem;
    
                        @include mix.mq(bg-tab) {
                            padding: 1.5rem;
                        }
    
                        @include mix.mq(sm-tab) {
                            padding: 1rem;
                        }
    
                        .about-inner-pic-cover {
                            margin-bottom: 1rem;
    
                            @include mix.mq(bg-tab) {
                                margin-bottom: .6rem;
                            }
    
                            @include mix.mq(sm-tab) {
                                margin-bottom: .4rem;
                            }
                        }
    
                        .about-inner-item-bottom {
                            background: #fff;
                            border-radius: 6px;
                            padding: .6rem;
                            height: 115px;
    
                            .about-inner-item-head {
                                @include mix.co;
                                font-size: 1.8rem;
                                font-style: italic;
                                letter-spacing: 2px;
                                text-align: left;
                                margin-bottom: .5rem;
    
                                @include mix.mq(bg-tab) {
                                    font-size: 1.2rem;
                                    letter-spacing: 1px;
                                }
    
                                @include mix.mq(sm-tab) {
                                    font-size: 1rem;
                                }
    
                                @include mix.mq(sp) {
                                    font-size: 1.5rem;
                                }
                            }
    
                            .pink {
                                color: #f4599e;
                            }
    
                            .skyblue {
                                color: #00a8ff;
                            }
    
                            .purple {
                                color: #984cf6;
                            }
    
                            .about-inner-item-content {
                                @include mix.mainFont(400,normal);
                                font-size: 1rem;
                                letter-spacing: 2px;
                                text-align: justify;
                                line-height: 1.5;
    
                                @include mix.mq(bg-tab) {
                                    font-size: .8rem;
                                    letter-spacing: 1px;
                                }
    
                                @include mix.mq(sm-tab) {
                                    font-size: .6rem;
                                }
    
                                @include mix.mq(sp) {
                                    font-size: .8rem;
                                }
                            }
    
                            .about-inner-item-content-third {
                                font-size: .6rem;
                            }
                        }
                    }
                }
            }

            .swiper-button-next, .swiper-button-prev {
                display: none;
                top: var(--swiper-navigation-top-offset,50%);

                @include mix.mq(sp) {
                    display: flex;
                }
            }

            .swiper-button-prev {
                left: var(--swiper-navigation-sides-offset,-15px);
            }

            .swiper-button-next {
                right: var(--swiper-navigation-sides-offset,-15px);
            }
            
        }
        

        

        .about-bottom-content {
            @include mix.mainFont(400,normal);
            font-size: 1.2rem;
            letter-spacing: 2px;
            padding: 10rem 0 4rem;

            @include mix.mq(sm-pc) {
                padding: 6rem 0 2rem;
            }

            @include mix.mq(bg-tab) {
                font-size: 1rem;
                padding: 7rem 0 3rem;
            }

            @include mix.mq(sm-tab) {
                font-size: .8rem;
                padding: 5rem 0 1rem;
            }

            @include mix.mq(sp) {
                padding: 2rem 0 1rem;
                line-height: 2.5;
            }
        }
    }
}

.strength-wrapper {
    width: 100%;
    height: auto;

    .strength-container {
        max-width: 70%;
        margin: 0 auto;
        text-align: center;
        padding: 12rem 0;

        @include mix.mq(sm-pc) {
            padding: 8rem 0;
        }

        @include mix.mq(bg-tab) {
            max-width: 90%;
        }

        @include mix.mq(sm-tab) {
            padding: 6rem 0;
        }

        .strength-head {
            @include mix.mainFont(bold,normal);
            font-size: 1.6rem;
            letter-spacing: 3px;
            margin-bottom: 5rem;

            @include mix.mq(bg-tab) {
                font-size: 1.5rem;
                letter-spacing: 1px;
            }

            @include mix.mq(sm-tab) {
                font-size: 1.2rem;
                margin-bottom: 4rem;
            }

            @include mix.mq(sp) {
                line-height: 2;
                margin-bottom: 3rem;
            }
        }

        .strength-content {
            @include mix.mainFont(400,normal);
            font-size: 1rem;
            letter-spacing: 2px;
            line-height: 2.5;
            text-align: justify;
            margin-bottom: 6rem;

            @include mix.mq(bg-tab) {
                font-size: 1rem;
                letter-spacing: 2px;
            }

            @include mix.mq(sm-tab) {
                font-size: .8rem;
                margin-bottom: 4rem;
            }
        }

        .strength-pic-inner {
            width: 90%;
            margin: 0 auto;

            @include mix.mq(sp) {
                width: 100%;
            }
        }
    }
}

.mv-wrapper {
    width: 100%;
    height: auto;
    background: #fafafa;

    .mv-container {
        max-width: 70%;
        margin: 0 auto;
        text-align: center;
        padding: 12rem 0 8rem;

        @include mix.mq(sm-pc) {
            padding: 8rem 0 2rem;
        }

        @include mix.mq(bg-tab) {
            max-width: 90%;
        }

        @include mix.mq(sm-tab) {
            padding: 6rem 0 4rem;
        }

        @include mix.mq(sp) {
            padding: 6rem 0 2rem;
        }

        .mv-head {
            @include mix.mainFont(bold,normal);
            font-size: 1.6rem;
            letter-spacing: 3px;
            margin-bottom: 3rem;
            margin-top: 6rem;

            @include mix.mq(bg-tab) {
                font-size: 1.5rem;
                letter-spacing: 1px;
            }

            @include mix.mq(sm-tab) {
                font-size: 1.2rem;
            }

            @include mix.mq(sp) {
                line-height: 2;
                margin-top: 3rem;
            }
        }

        .mv-content {
            @include mix.mainFont(400,normal);
            font-size: 1rem;
            letter-spacing: 2px;
            line-height: 2.5;
            text-align: justify;
            margin-bottom: 6rem;

            @include mix.mq(bg-tab) {
                font-size: 1rem;
                letter-spacing: 2px;
            }

            @include mix.mq(sm-tab) {
                font-size: .8rem;
            }

            @include mix.mq(sp) {
                margin-bottom: 3rem;
            }
        }
    }
}

.place-wrapper {
    width: 100%;
    height: auto;

    .place-container {
        max-width: 70%;
        margin: 0 auto;
        text-align: center;
        padding: 10rem 0;

        @include mix.mq(bg-tab) {
            max-width: 90%;
        }

        @include mix.mq(sm-tab) {
            padding: 6rem 0;
        }

        .place-head {
            @include mix.mainFont(bold,normal);
            font-size: 2rem;
            letter-spacing: 3px;
            margin-bottom: 5rem;
            line-height: 2;

            @include mix.mq(bg-tab) {
                font-size: 1.7rem;
                margin-bottom: 4rem;
            }

            @include mix.mq(sm-tab) {
                font-size: 1.4rem;
                letter-spacing: 2px;
            }

            @include mix.mq(sp) {
                font-size: 1.4rem;
                margin-bottom: 3rem;
            }
        }

        .place-subhead {
            @include mix.mainFont(bold,normal);
            font-size: 1.7rem;
            letter-spacing: 3px;
            margin-bottom: 5rem;
            background: linear-gradient(350deg,#2c8ec4 45%,#31cdb0 52%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            @include mix.mq(bg-tab) {
                font-size: 1.5rem;
                margin-bottom: 4rem;
            }

            @include mix.mq(sm-tab) {
                font-size: 1.2rem;
            }

            @include mix.mq(sp) {
                font-size: 1.1rem;
                letter-spacing: 1.5px;
                margin-bottom: 3rem;
            }
        }

        .place-content {
            @include mix.mainFont(400,normal);
            font-size: 1rem;
            letter-spacing: 2px;
            line-height: 2.5;
            text-align: justify;
            margin-bottom: 4rem;

            @include mix.mq(bg-tab) {
                font-size: 1rem;
                letter-spacing: 2px;
            }

            @include mix.mq(sm-tab) {
                font-size: .8rem;
                margin-bottom: 4rem;
            }

            @include mix.mq(sp) {
                margin-bottom: 3rem;
                letter-spacing: 1px;
            }
        }

        .place-inner {
            display: grid;
            grid-template-columns: repeat(2,1fr);
            margin: 0 auto 4rem;
            width: 80%;

            @include mix.mq(sp) {
                margin-bottom: 3rem;
                width: 100%;
            }
        }

        .place-bottom-content {
            @include mix.mainFont(400,normal);
            font-size: 1rem;
            letter-spacing: 2px;
            line-height: 2.5;
            text-align: justify;
            margin-bottom: 5rem;

            @include mix.mq(bg-tab) {
                font-size: 1rem;
                letter-spacing: 2px;
            }

            @include mix.mq(sm-tab) {
                font-size: .8rem;
            }

            @include mix.mq(sp) {
                margin-bottom: 3rem;
            }
        }

        .place-bottom-pic {
            width: 80%;

            @include mix.mq(sp) {
                width: 100%;
            }
        }
    }
}

.ad-wrapper {
    width: 100%;
    height: auto;
    background: url("../../dest/img/ad-background.png") center/cover no-repeat;

    .ad-container {
        max-width: 70%;
        margin: 0 auto;
        text-align: center;
        padding: 8rem 0 6rem;

        @include mix.mq(bg-tab) {
            max-width: 90%;
            padding: 8rem 0;
        }

        @include mix.mq(sm-tab) {
            padding: 8rem 0 4rem;
        }

        @include mix.mq(sp) {
            padding: 6rem 0 3rem;
        }

        .ad-head {
            @include mix.mainFont(bold,normal);
            font-size: 1.8rem;
            letter-spacing: 3px;
            margin-bottom: 4rem;
            line-height: 2;
            background: linear-gradient(90deg,#f6bf75 15%,#d77185,#8766ac,#4150b1);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            @include mix.mq(bg-tab) {
                font-size: 1.5rem;
                letter-spacing: 1px;
                margin-bottom: 4rem;
            }

            @include mix.mq(sm-tab) {
                font-size: 1.4rem;
            }

            @include mix.mq(sp) {
                margin-bottom: 3rem;
            }

            .ad-head-deco {
                font-size: 2.6rem;
                letter-spacing: 8px;

                @include mix.mq(bg-tab) {
                    font-size: 2rem;
                    letter-spacing: 8px;
                }

                @include mix.mq(sm-tab) {
                    font-size: 1.8rem;
                    letter-spacing: 6px;
                }

                @include mix.mq(sp) {
                    font-size: 1.6rem;
                    letter-spacing: 2px;
                }
            }
        }

        .ad-content {
            @include mix.mainFont(400,normal);
            font-size: 1rem;
            letter-spacing: 2px;
            line-height: 2.5;
            text-align: justify;
            margin-bottom: 5rem;

            @include mix.mq(bg-tab) {
                font-size: 1rem;
                letter-spacing: 2px;
                margin-bottom: 4rem;
            }

            @include mix.mq(sm-tab) {
                font-size: .8rem;
            }

            @include mix.mq(sp) {
                margin-bottom: 3rem;
                letter-spacing: 1px;
            }
        }

        .ad-inner-wrap {
            margin: 8rem auto;

            @include mix.mq(bg-tab) {
                margin: 6rem auto;
            }

            @include mix.mq(sp) {
                margin: 4rem auto 3rem;
            }

            .ad-inner {
                text-align: left;
                background: rgba(255,255,255,.2);
                border: 1px solid rgba(255,255,255,.4);
                border-radius: 20px;
                margin: 6rem auto;
                padding: 2rem 2rem 4rem;
                box-shadow: 12px 12px 12px 6px rgba(0,0,0,.1);
                position: relative;

                @include mix.mq(bg-tab) {
                    margin: 4rem auto;
                }

                @include mix.mq(sp) {
                    padding: 1rem 1rem 3rem;
                    margin: 3rem auto;
                }
    
                .ad-inner-head {
                    @include mix.mainFont(bold,italic);
                    font-size: 2rem;
                    letter-spacing: 3px;
                    margin-bottom: .5rem;

                    @include mix.mq(bg-tab) {
                        font-size: 1.7rem;
                        letter-spacing: 2px;
                    }

                    @include mix.mq(sm-tab) {
                        font-size: 1.4rem;
                    }

                    @include mix.mq(sp) {
                        font-size: 1.2rem;
                    }
    
                    .ad-inner-deco {
                        font-size: 2.6rem;

                        @include mix.mq(bg-tab) {
                            font-size: 2rem;
                            letter-spacing: 2px;
                        }

                        @include mix.mq(sm-tab) {
                            font-size: 1.8rem;
                        }

                        @include mix.mq(sp) {
                            font-size: 1.4rem;
                        }
                    }
                }
    
                .ad-inner-content {
                    @include mix.mainFont(400,normal);
                    font-size: 1rem;
                    letter-spacing: 2px;
                    line-height: 2.5;

                    @include mix.mq(bg-tab) {
                        font-size: 1rem;
                        letter-spacing: 2px;
                    }

                    @include mix.mq(sm-tab) {
                        font-size: .8rem;
                    }
                }
            }

            .ad-inner-first:after {
                content: url("../../dest/img/ad-inner-head-1.png");
                position: absolute;
                top: -60px;
                right: -5px;
                transform: scale(1);

                @include mix.mq(sm-pc) {
                    right: -62px;
                    transform: scale(.8);
                }

                @include mix.mq(bg-tab) {
                    top: -50px;
                    right: 0;
                    transform: scale(1);
                }

                @include mix.mq(sm-tab) {
                    right: -90px;
                    transform: scale(.7);
                }

                @include mix.mq(sp) {
                    top: -72px;
                    right: -178px;
                    transform: scale(.4);
                }
            }

            .ad-inner-second:after {
                content: url("../../dest/img/ad-inner-head-2.png");
                position: absolute;
                top: -60px;
                right: -5px;
                transform: scale(1);

                @include mix.mq(sm-pc) {
                    right: -62px;
                    transform: scale(.8);
                }

                @include mix.mq(bg-tab) {
                    top: -50px;
                    right: 0;
                    transform: scale(1);
                }

                @include mix.mq(sm-tab) {
                    right: -90px;
                    transform: scale(.7);
                }

                @include mix.mq(sp) {
                    top: -72px;
                    right: -178px;
                    transform: scale(.4);
                }
            }

            .ad-inner-third:after {
                content: url("../../dest/img/ad-inner-head-3.png");
                position: absolute;
                top: -60px;
                right: -5px;
                transform: scale(1);

                @include mix.mq(sm-pc) {
                    right: -62px;
                    transform: scale(.8);
                }

                @include mix.mq(bg-tab) {
                    top: -50px;
                    right: 0;
                    transform: scale(1);
                }

                @include mix.mq(sm-tab) {
                    right: -90px;
                    transform: scale(.7);
                }

                @include mix.mq(sp) {
                    top: -72px;
                    right: -178px;
                    transform: scale(.4);
                }
            }
        }
    }
}

.feture-wrapper {
    width: 100%;
    height: auto;
    background: #fafafa;

    .feture-container {
        max-width: 70%;
        margin: 0 auto;
        text-align: center;
        padding: 8rem 0 6rem;

        @include mix.mq(bg-tab) {
            max-width: 90%;
            padding: 8rem 0;
        }

        @include mix.mq(sp) {
            padding: 6rem 0 3rem;
        }

        .feture-inner {

            .feture-inner-head {
                @include mix.mainFont(bold,normal);
                font-size: 2rem;
                letter-spacing: 3px;
                margin-bottom: 4rem;
                background: linear-gradient(90deg,#f6bf75 25%,#d77185,#8766ac,#4150b1);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;

                @include mix.mq(bg-tab) {
                    font-size: 1.7rem;
                    letter-spacing: 2px;
                }

                @include mix.mq(sm-tab) {
                    font-size: 1.4rem;
                }

                @include mix.mq(sp) {
                    margin-bottom: 2rem;
                }
            }

            .feture-inner-content {
                @include mix.mainFont(bold,normal);
                font-size: 1.3rem;
                letter-spacing: 3px;
                line-height: 2;
                margin-bottom: 5rem;

                @include mix.mq(bg-tab) {
                    font-size: 1.2rem;
                    letter-spacing: 2px;
                    margin-bottom: 4rem;
                }

                @include mix.mq(sm-tab) {
                    font-size: 1rem;
                }

                @include mix.mq(sp) {
                    display: none;
                }
            }

            .sp-feture-inner-content {
                @include mix.mainFont(bold,normal);
                font-size: .8rem;
                margin-bottom: 3rem;
                letter-spacing: 2px;
                line-height: 2;
                display: none;

                @include mix.mq(sp) {
                    display: block;
                }
            }

            .feture-inner-pic {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 5rem;

                @include mix.mq(bg-tab) {
                    margin-bottom: 4rem;
                }

                @include mix.mq(sp) {
                    flex-direction: column;
                    gap: 1.5rem 0;
                    margin-bottom: 3rem;
                }

                .feture-pic {
                    width: 50%;
                    padding: 0 1.5rem;

                    @include mix.mq(sp) {
                        width: 100%;
                        padding: 0;
                    }
                }
            }

            .feture-bottom-content {
                @include mix.mainFont(400,normal);
                font-size: 1rem;
                letter-spacing: 2px;
                line-height: 2.5;

                @include mix.mq(bg-tab) {
                    font-size: 1rem;
                    letter-spacing: 2px;
                }

                @include mix.mq(sm-tab) {
                    font-size: .8rem;
                    letter-spacing: 1px;
                }
            }
        }

        .feture-inner-top {
            margin-bottom: 15rem;

            @include mix.mq(sm-tab) {
                margin-bottom: 12rem;
            }

            @include mix.mq(sp) {
                margin-bottom: 8rem;
            }
        }
    }
}

.com-about-wrapper {
    width: 100%;
    height: auto;
    background: url("../../dest/img/material-33.png") center/cover no-repeat;

    .com-about-container {
        max-width: 70%;
        margin: 0 auto;
        text-align: center;
        padding: 8rem 0;

        @include mix.mq(bg-tab) {
            max-width: 90%;
            padding: 8rem 0;
        }

        @include mix.mq(sp) {
            padding: 4rem 0 3rem;
        }

        .com-about-head {
            @include mix.mainFont(bold,normal);
            font-size: 2rem;
            letter-spacing: 3px;
            margin-bottom: 6rem;
            line-height: 2;

            @include mix.mq(bg-tab) {
                font-size: 1.5rem;
                letter-spacing: 2px;
                margin-bottom: 4rem;
            }

            @include mix.mq(sm-tab) {
                font-size: 1.4rem;
            }

            @include mix.mq(sp) {
                margin-bottom: 3rem;
            }
        }

        .com-about-inner {
            text-align: left;
            background: rgba(255,255,255,.2);
            border-radius: 20px;
            margin: 6rem auto;
            padding: 8rem 5rem 1rem;
            box-shadow: 4px 4px 4px 4px rgba(0,0,0,.1);

            @include mix.mq(sm-pc) {
                margin: 4rem auto;
                padding: 8rem 3rem 1rem;
            }

            @include mix.mq(sm-tab) {
                padding: 8rem 2.2rem 1rem;
            }

            @include mix.mq(sp) {
                padding: 4rem 2rem 1rem;
            }

            .com-about-contents {
                margin-bottom: 6rem;
                position: relative;

                @include mix.mq(sm-tab) {
                    margin-bottom: 4rem;
                }

                @include mix.mq(sp) {
                    margin-bottom: 3rem;
                }

                .com-about-contents-head {
                    @include mix.mainFont(bold,italic);
                    font-size: 1.8rem;
                    letter-spacing: 3px;
                    margin-left: 3rem;
                    margin-bottom: 1.2rem;
                    line-height: 2;
                    display: inline-block;
                    background: linear-gradient(-50deg,#007cde,#01e9bd);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;

                    @include mix.mq(bg-tab) {
                        font-size: 1.5rem;
                        letter-spacing: 2px;
                        margin-bottom: 1.2rem;
                    }

                    @include mix.mq(sm-tab) {
                        font-size: 1.4rem;
                        margin-bottom: 1rem;
                    }

                    @include mix.mq(sp) {
                        font-size: 1.2rem;
                        line-height: 1.5;
                        margin-left: 1rem;
                    }
                }
                .com-about-contents-para {
                    @include mix.mainFont(400,normal);
                    font-size: 1rem;
                    letter-spacing: 2px;
                    line-height: 2.5;

                    @include mix.mq(bg-tab) {
                        font-size: 1rem;
                        letter-spacing: 2px;
                    }

                    @include mix.mq(sm-tab) {
                        font-size: .8rem;
                    }

                    @include mix.mq(sp) {
                        line-height: 2;
                        letter-spacing: 1px;
                        text-align: justify;
                    }
                }
            }

            .com-about-contents-first:before {
                content: url("../../dest/img/com-about-content-back-1.png");
                position: absolute;
                top: -55px;
                left: -40px;
                transform: scale(.9);

                @include mix.mq(bg-tab) {
                    top: -55px;
                    left: -50px;
                    transform: scale(.8);
                }

                @include mix.mq(sm-tab) {
                    left: -65px;
                    transform: scale(.7);
                }

                @include mix.mq(sp) {
                    left: -95px;
                    transform: scale(.5);
                }
            }

            .com-about-contents-second:before {
                content: url("../../dest/img/com-about-content-back-2.png");
                position: absolute;
                top: -55px;
                left: -40px;
                transform: scale(.9);

                @include mix.mq(bg-tab) {
                    top: -55px;
                    left: -50px;
                    transform: scale(.8);
                }

                @include mix.mq(sm-tab) {
                    left: -65px;
                    transform: scale(.7);
                }

                @include mix.mq(sp) {
                    left: -95px;
                    transform: scale(.5);
                }
            }

            .com-about-contents-third:before {
                content: url("../../dest/img/com-about-content-back-3.png");
                position: absolute;
                top: -55px;
                left: -40px;
                transform: scale(.9);

                @include mix.mq(bg-tab) {
                    top: -55px;
                    left: -50px;
                    transform: scale(.8);
                }

                @include mix.mq(sm-tab) {
                    left: -65px;
                    transform: scale(.7);
                }

                @include mix.mq(sp) {
                    left: -95px;
                    transform: scale(.5);
                }
            }

            .com-about-contents-fourth:before {
                content: url("../../dest/img/com-about-content-back-4.png");
                position: absolute;
                top: -55px;
                left: -40px;
                transform: scale(.9);

                @include mix.mq(bg-tab) {
                    top: -55px;
                    left: -50px;
                    transform: scale(.8);
                }

                @include mix.mq(sm-tab) {
                    left: -65px;
                    transform: scale(.7);
                }

                @include mix.mq(sp) {
                    left: -95px;
                    transform: scale(.5);
                }
            }
        }
    }
}

.apparel-wrapper {
    width: 100%;
    height: auto;
    background: url("../../dest/img/material-35.png") center/cover no-repeat;

    .apparel-container {
        max-width: 70%;
        margin: 0 auto;
        text-align: center;
        padding: 12rem 0;

        @include mix.mq(bg-tab) {
            max-width: 90%;
            padding: 6rem 0;
        }

        .apparel-head {
            @include mix.heroExtraBold;
            font-style: normal;
            font-size: 2rem;
            letter-spacing: 3px;
            margin-bottom: 8rem;

            @include mix.mq(bg-tab) {
                font-size: 1.5rem;
                letter-spacing: 2px;
                margin-bottom: 8rem;
            }

            @include mix.mq(sm-tab) {
                font-size: 1.4rem;
                margin-bottom: 4rem;
            }

            @include mix.mq(sp) {
                margin-bottom: 3rem;
            }
        }

        .apparel-inner {
            display: grid;
            grid-template-columns: repeat(3,1fr);
            align-items: start;
            gap: 2rem 1.5rem;
            margin-bottom: 8rem;

            @include mix.mq(sm-pc) {
                gap: 1.5rem 1.2rem;
                margin-bottom: 7rem;
            }

            @include mix.mq(bg-tab) {
                gap: 2rem 1.2rem;
                margin-bottom: 6rem;
            }
            
            @include mix.mq(sm-tab) {
                gap: 1rem 1.2rem;
            }

            @include mix.mq(sp) {
                display: none;
            }

            .apparel-inner-contents {
                background: rgba(255,255,255,.2);
                border: 1px solid rgba(255,255,255,.4);
                border-radius: 20px;
                padding: 2rem 3rem 5rem;
                box-shadow: 12px 12px 12px 6px rgba(0,0,0,.1);
                height: 100%;

                @include mix.mq(sm-pc) {
                    padding: 1rem 1.5rem 2rem;
                }

                @include mix.mq(bg-tab) {
                    padding: 1rem 1.5rem 3rem;
                }

                @include mix.mq(sm-tab) {
                    padding: .6rem 1.2rem 2rem;
                }

                .apparel-inner-contents-pic {
                    height: 70%;
                    object-fit: contain;
                    margin-top: 2rem;
                    margin-bottom: 3rem;

                    @include mix.mq(sm-pc) {
                        margin-top: 1rem;
                        margin-bottom: 1rem;
                    }

                    @include mix.mq(bg-tab) {
                        margin-bottom: 1.5rem;
                    }

                    @include mix.mq(sm-tab) {
                        margin-top: 1rem;
                        margin-bottom: .8rem;
                    }
                }

                .apparel-inner-contents-head {
                    @include mix.heroRegular;
                    font-size: 1.2rem;
                    letter-spacing: 2px;
                    margin-bottom: 1rem;

                    @include mix.mq(sm-pc) {
                        font-size: 1rem;
                    }

                    @include mix.mq(bg-tab) {
                        font-size: .8rem;
                    }

                    @include mix.mq(sm-tab) {
                        font-size: .6rem;
                    }
                }

                .apparel-inner-contents-para {
                    @include mix.heroRegular;
                    font-size: 1.2rem;
                    letter-spacing: 2px;

                    @include mix.mq(sm-pc) {
                        font-size: 1rem;
                    }

                    @include mix.mq(bg-tab) {
                        font-size: .8rem;
                    }

                    @include mix.mq(sm-tab) {
                        font-size: .6rem;
                    }
                }
            }
        }

        .swiper-box {
            position: relative;

            .swiper-apparel {
                width: 335px;
                height: 465px;
                display: none;
    
                @include mix.mq(sp) {
                    display: block;
                    margin-bottom: 4rem;
                }
    
                .swiper-wrapper {
                    padding: 1rem 0;
    
                    .apparel-inner-contents {
                        background: rgba(255,255,255,.2);
                        border: 1px solid rgba(255,255,255,.4);
                        border-radius: 20px;
                        padding: 2rem 3rem 5rem;
                        box-shadow: 1px 2px 10px 4px rgba(0, 0, 0, 0.1);
                        height: 370px;
                        width: 280px;
                        margin: 0 auto;
        
                        @include mix.mq(bg-tab) {
                            padding: 1rem 1.5rem 3rem;
                        }
        
                        @include mix.mq(sm-tab) {
                            padding: .6rem 1.2rem 2rem;
                        }
        
                        .apparel-inner-contents-pic {
                            height: 70%;
                            object-fit: contain;
                            margin-top: 2rem;
                            margin-bottom: 3rem;
        
                            @include mix.mq(bg-tab) {
                                margin-bottom: 1.5rem;
                            }
        
                            @include mix.mq(sm-tab) {
                                margin-top: 1rem;
                                margin-bottom: .8rem;
                            }
                        }
        
                        .apparel-inner-contents-head {
                            @include mix.heroRegular;
                            font-size: 1rem;
                            letter-spacing: 1px;
                            margin-bottom: 1rem;
                        }
        
                        .apparel-inner-contents-para {
                            @include mix.heroRegular;
                            font-size: 1rem;
                            letter-spacing: 1px;
                        }
                    }
                }
            }

            .swiper-button-next, .swiper-button-prev {
                display: none;
                top: var(--swiper-navigation-top-offset,50%);

                @include mix.mq(sp) {
                    display: flex;
                }
            }

            .swiper-button-prev {
                left: var(--swiper-navigation-sides-offset,-10px);
            }

            .swiper-button-next {
                right: var(--swiper-navigation-sides-offset,-10px);
            }
        }
        

        .apparel-link-inner {

            .apparel-link {
                @include mix.btnFont;
                background: rgba(0,0,0,1);
                color: #fff;
                font-size: 1.6rem;
                letter-spacing: 1px;
                padding: 1rem 12rem;
                border-radius: 40px;
                cursor: pointer;
                transition: all .5s;

                &:hover {
                    background: rgba(0,0,0,.6);
                }

                @include mix.mq(bg-tab) {
                    font-size: 1.2rem;
                    padding: 1rem 8rem;
                }

                @include mix.mq(sm-tab) {
                    font-size: 1rem;
                    padding: 1rem 6rem;
                }
            }
        }
    }
}

.public-wrapper {
    width: 100%;
    height: auto;

    .public-container {
        max-width: 70%;
        margin: 0 auto;
        text-align: center;
        padding: 12rem 0;

        @include mix.mq(bg-tab) {
            max-width: 90%;
            padding: 8rem 0;
        }

        .public-head {
            @include mix.mainFont(bold,normal);
            font-size: 2rem;
            letter-spacing: 3px;
            margin-bottom: 6rem;
            line-height: 2;

            @include mix.mq(bg-tab) {
                font-size: 1.5rem;
                letter-spacing: 2px;
                margin-bottom: 4rem;
            }

            @include mix.mq(sm-tab) {
                font-size: 1.4rem;
            }
        }

        .public-inner {
            display: grid;
            grid-template-columns: repeat(2,1fr);
            gap: 4rem;
            width: 80%;
            margin: 0 auto;

            @include mix.mq(bg-tab) {
                gap: 2rem;
            }

            @include mix.mq(sm-tab) {
                gap: 3rem;
            }

            @include mix.mq(sp) {
                width: 100%;
                gap: 2rem;
            }

            .public-inner-content {

                .public-inner-pic {
                    margin-bottom: 1.2rem;
                }

                .public-inner-content-para {
                    @include mix.mainFont(bold,normal);
                    font-size: 1rem;
                    letter-spacing: 1px;
                    text-align: center;
                    transform: translateX(10px);

                    @include mix.mq(sm-tab) {
                        font-size: .7rem;
                    }

                    @include mix.mq(sp) {
                        transform: translateX(0);
                    }
                }
            }
        }
    }
}

footer {
    width: 100%;
    height: auto;
    background: url("../../dest/img/footer-background.png") top/cover no-repeat;

    @include mix.mq(sp) {
        background: url("../../dest/img/footer-background.png") left/cover no-repeat;
    }

    .footer-container {
        padding: 6rem 2rem 3rem;

        @include mix.mq(bg-tab) {
            padding: 5rem 1rem 2rem;
        }

        @include mix.mq(sp) {
            padding: 3rem 1rem 2rem;
        }

        .footer-inner {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 22rem;

            @include mix.mq(bg-tab) {
                margin-bottom: 18rem;
            }

            @include mix.mq(sp) {
                flex-direction: column;
                align-items: start;
                margin-bottom: 8rem;
            }

            .footer-inner-left {
                width: 22%;

                @include mix.mq(sp) {
                    width: 70%;
                    margin-bottom: 1.5rem;
                    transform: translateX(-12px);
                }
            }

            .footer-inner-right {
                margin-right: 2rem;

                @include mix.mq(bg-tab) {
                    margin-right: 1rem;
                }

                @include mix.mq(sm-tab) {
                    margin-right: 0;
                }

                .footer-inner-right-lists {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 0 3rem;

                    @include mix.mq(bg-tab) {
                        gap: 0 2rem;
                    }

                    @include mix.mq(sm-tab) {
                        gap: 0 1rem;
                    }

                    @include mix.mq(sp) {
                        flex-direction: column;
                        align-items: start;
                    }

                    .footer-inner-right-list {

                        @include mix.mq(sp) {
                            margin-bottom: 1rem;
                        }

                        .footer-inner-right-link {
                            @include mix.mainFont(400,normal);
                            font-size: 1rem;
                            letter-spacing: 2px;

                            @include mix.mq(bg-tab) {
                                font-size: 1rem;
                                letter-spacing: 1.5px;
                            }

                            @include mix.mq(sm-tab) {
                                font-size: .6rem;
                                letter-spacing: 1.2px;
                            }

                            @include mix.mq(sp) {
                                font-size: .8rem;
                                letter-spacing: 1px;
                            }
                        }
                    }
                }
            }
        }
        
        .copywrite-inner {
            text-align: center;

            .copywrite {
                @include mix.heroRegular;
                font-size: .8rem;
                letter-spacing: 2px;

                @include mix.mq(sm-tab) {
                    font-size: .6rem;
                    letter-spacing: 1px;
                }
            }
        }
    }
}