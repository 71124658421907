$mainFontColor: #353535;

$width: 1500px;
$breakpoints: (
  "sp": "screen and (max-width: 420px)",
  "sm-tab": "screen and (max-width: 768px)",
  "bg-tab": "screen and (max-width: 1200px)",
  "pre-sm-pc": "screen and (max-width: 1265px)",
  "sm-pc": "screen and (max-width: 1530px)",
  "pc": "screen and (min-width: #{$width})",
  "bg-pc": "screen and (min-width: 2200px)",
);

@mixin mq($breakpoint: sp) {
    @media #{map-get($breakpoints, $breakpoint)} {
      @content;
    }
}

@mixin hero {
    font-family: "new-hero", sans-serif;
    font-weight: 900;
    font-style: italic;
}

@mixin heroRegular {
  font-family: new-hero, sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin heroExtraBold {
  font-family: new-hero, sans-serif;
  font-weight: 800;
  font-style: normal;
}

@mixin co {
    font-family: "co-headline", sans-serif;
    font-weight: 700;
    font-style: normal; 
}

@mixin mainFont($weight,$style) {
    font-family:"Hiragino Sans","Hiragino Kaku Gothic ProN","MS Gothic","Meiryo",sans-serif;
    font-weight: $weight;
    font-style: $style;
}

@mixin btnFont {
  font-family: "dunbar-low", sans-serif;
  font-weight: 200;
  font-style: normal;
}